import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';
import * as React from 'react';
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useParams,
} from 'react-router-dom';

// MODULES
import GetOfferContactsError from '@exceptions/GetOfferContactsError';
import PostOfferContactsError from '@exceptions/PostOfferContactError';
import {
	convertFileToBase64,
} from '@modules/images';
import {
	handleErrorResponse,
} from '@modules/utils';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumMediaType,
} from '@enums/media.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// LAYOUTS
import {
	OfferEnumsStateProps,
	OfferStateContactsProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	getMarketers,
	getOfferContacts,
	postMarketers,
	postOfferContacts,
} from '@layouts/Pages/PageOfferEntry/config/fetch';

//COMPONENTS
import Button from '@components/button';
import {
	Dropzone,
	DropzoneChildDefault,
	DropzoneChildOver,
	DropzoneChildProcessing,
} from '@components/dropzone';
import Form from '@components/form';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import InputText from '@components/form/input-text';
import Image from '@components/image';
import StatusBanner from '@components/status-banner';

// ASSETS
import DropYourFileImage from '@assets/images/lpdipro/drop_your_file.png';

//STYLES
import LabelStatus from '@components/label-status';
import styles from './ModalOffersContact.module.scss';

interface ModalOfferContactProps {
	offerEnumsState: OfferEnumsStateProps;
	offerStateContacts: OfferStateContactsProps;
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
}

const ModalOffersContact = ({
	offerEnumsState,
	offerStateContacts,
	setIsOpenModal,
	setOfferStateContacts,
}: ModalOfferContactProps) => {
	const { t } = useTranslation();
	const formCustomContactRef = React.useRef(null);
	const {offerid} = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : undefined;

	const [
		isDisabled,
		setIsDisabled
	] = useState(false);

	const [
		marketers,
		setMarketers,
	] = useState({
		is_loaded: false,
		data: undefined,
	});

	const [
		companyValue,
		setCompanyValue,
	] = useState(undefined);

	const [
		companiesList,
		setCompaniesList,
	] = useState(undefined);

	const [
		isNewCompany,
		setIsNewCompany,
	] = useState(false);

	const [
		image,
		setImage,
	] = useState(undefined);

	const mediaTypeAccepted = {
		'image/*': [
			'.png',
			'.jpg',
			'.jpeg'
		],
	};

	const [
		isProcessing,
		setIsProcessing,
	] =	useState(false);

	const [
		query,
		setQuery,
	] = useState(undefined);

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenModal(false);
	});

	useEffect(() => {
		if (companyValue && query) {
			getMarketers(query).then(responseParsed => {
				if (responseParsed.status === 200) {
					setCompaniesList(responseParsed.payload.marketers);
					if (responseParsed.payload.marketers.length === 0) {
						setIsNewCompany(true);
					} else {
						setIsNewCompany(false);
					}
				}
			});
		} else {
			setCompaniesList(undefined);
		}
	}, [
		query
	]);

	useDebouncedEffect(() => {
		if (companyValue?.length >= 3) {
			setQuery(companyValue);
		} else {
			setQuery(undefined);
			setIsNewCompany(false);
		}
	}, [
		companyValue
	], APP_CONF_VARS.timeout.debounce);

	const handleOnSubmit = async () => {
		setOfferStateContacts({
			...offerStateContacts,
			is_loaded: false,
		});
		try {
			setIsDisabled(true);

			// Post marketer
			const postMarketerResponse = await postMarketers(marketers.data);
			const contactId = postMarketerResponse.payload.contact_id;

			// Post offer contacts
			const postOfferContactsResponse = await postOfferContacts(currentOfferId, {
				contact_id: contactId,
			});
			handleErrorResponse(postOfferContactsResponse, PostOfferContactsError);

			// Get offer contacts
			const getOfferContactsResponse = await getOfferContacts(currentOfferId);
			handleErrorResponse(getOfferContactsResponse, GetOfferContactsError);

			setOfferStateContacts({
				contacts: getOfferContactsResponse.payload,
				is_loaded: true,
			});

		} catch (error) {
			setOfferStateContacts({
				...offerStateContacts,
				is_loaded: true,
			});
		} finally {
			setIsDisabled(false);
			setIsOpenModal(false);
		}
	};

	const roleOptions = offerEnumsState?.contact_roles.map((role) => {
		return {
			label: t('format.capitalize', {
				text: t(`page.offers_create_edit.contacts.role.${role.key}`)
			}),
			value: role.value.toString(),
			inputValue: {
				label: t('format.capitalize', {
					text: t(`page.offers_create_edit.contacts.role.${role.key}`)
				}),
			},
		};
	});

	const companiesOptions = companiesList ? companiesList.map((list: {
		company: string;
		id: number;
	}) => {
		return {
			value: list.id,
			inputValue: {
				label: list.company,
			},
			label: list.company,
		};
	}) : [
	];

	const dropzoneElement = (
		<>
			<div className={styles.dropzone_description}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.modal.logo.company.before')
				})}
				<LabelStatus
					className={styles.dropzone_description_status}
					text={t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.modal.logo.company.status')
					})}
					theme={EnumStatusTheme.INFO}
				/>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.modal.logo.company.after')
				})}
			</div>
			<Dropzone
				isProcessing={isProcessing}
				options={{
					accept: mediaTypeAccepted,
					maxSize: 10485760
				}}
				onDrop={(acceptedFiles) => {
					setIsProcessing(true);

					acceptedFiles.forEach(async (file: File) => {
						const formatedFile = await convertFileToBase64(file).then((convertedFile) => {
							return {
								filename: file.name,
								type: EnumMediaType.PHOTO,
								content: convertedFile,
							};
						});

						setImage(formatedFile);

						setMarketers({
							...marketers,
							data: {
								...marketers.data,
								marketer: {
									...marketers.data?.marketer,
									logo: formatedFile.content,
								},
							},
						});
						setIsProcessing(false);
					});
				}}
			>
				<DropzoneChildDefault
					className={styles.dropzone}
					key='1'
				>
					<span
						className={[
							styles.line,
						].join(' ')}
					>
						{t('page.offers_create_edit.media.image_format_should_be_square')}
					</span>
					<span className={styles.line}>

						{t('format.capitalize', {
							text: t('page.offers_create_edit.media.drag_and_drop_your_photos')
						})}
					</span>
					<span
						className={[
							styles.line,
							styles.surroundedbydashes
						].join(' ')}
					>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.media.or')
						})}
					</span>
					<Button
						className={styles.button}
						label={t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.modal.select_your_file')
						})}
					/>
				</DropzoneChildDefault>
				<DropzoneChildOver
					className={[
						styles.dropzone,
						styles.active
					].join(' ')}
					key='2'
				>
					<Image
						alt={t('format.capitalize', {
							text: t('page.offers_create_edit.media.drop_your_files_here')
						})}
						className={styles.dropzone__image}
						src={DropYourFileImage}
					/>
					<span className={styles.line}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.media.drop_your_files_here')
						})}
					</span>
				</DropzoneChildOver>,
				{isProcessing && (
					<DropzoneChildProcessing
						className={[
							styles.dropzone,
							styles.isprocessing
						].join(' ')}
						key='4'
					>
						<div className={styles.line}>
							{t('format.capitalize', {
								text: t('general.loading')
							})}
						</div>
					</DropzoneChildProcessing>
				)}
			</Dropzone>
		</>
	);

	return (
		<Form
			innerRef={formCustomContactRef}
			method={EnumQueryMethods.POST}
			onSuccess={handleOnSubmit}
		>
			<div className={`${styles.offers_contact}`}>
				<div className={styles.col}>
					<div className={styles.form_elements}>
						<div className={styles.title}>{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.modal.company.title')
						})}</div>
						<InputMultiSelect
							clearInputValue={false}
							hasErrorMessage={false}
							hasShadowMenu={true}
							isMulti={false}
							label={`${t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.company.label')
							})} *`}
							name='company_name'
							options={companiesOptions}
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.company.placeholder')
							})}
							required={true}
							onChange={(selected: OptionType) => {
								if (selected) {
									setIsNewCompany(false);
									setMarketers({
										...marketers,
										data: {
											...marketers.data,
											marketer: {
												...marketers.data?.marketer,
												id: selected?.value || undefined,
												company: selected?.inputValue?.label,
											}
										},
									});
								}
							}}
							onInputChange={(newValue: string) => {
								if (newValue === '') {
									setQuery(undefined);
								} else {
									setCompanyValue(newValue);
									setMarketers({
										...marketers,
										data: {
											...marketers.data,
											marketer: {
												...marketers.data?.marketer,
												company: newValue,
											}
										},
									});
								}
							}}
						/>

						{isNewCompany ? (
							<>
								{image?.filename ? (
									<div className={styles.preview_container}>
										<img
											alt={image?.filename}
											className={styles.preview_image}
											src={image?.content}
										/>
										<span className={styles.preview_name}>{image?.filename}</span>
									</div>
								) : undefined}
								{dropzoneElement}
							</>
						) : undefined}
						<div className={styles.title}>{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.modal.first_name.title')
						})}</div>
						<InputText
							label={`${t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.first_name.label')
							})} *`}
							name='firstname'
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.first_name.placeholder')
							})}
							required={true}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								setMarketers({
									...marketers,
									data: {
										...marketers.data,
										contact: {
											...marketers.data?.contact,
											first_name: target.value,
										},
									},
								});
							}}
						/>
						<InputText
							label={`${t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.last_name.label')
							})} *`}
							name='lastname'
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.last_name.placeholder')
							})}
							required={true}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								setMarketers({
									...marketers,
									data: {
										...marketers.data,
										contact: {
											...marketers.data?.contact,
											last_name: target.value,
										},
									},
								});
							}}
						/>
						<InputMultiSelect
							hasShadowMenu={true}
							isMulti={false}
							label={`${t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.role.label')
							})} *`}
							maxMenuHeight={140}
							name='role'
							options={roleOptions}
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.role.placeholder')
							})}
							required={true}
							onChange={(selected: OptionType) => {
								setMarketers({
									...marketers,
									data: {
										...marketers.data,
										contact: {
											...marketers.data?.contact,
											role_id: selected?.value,
										},
									},
								});

							}}
						/>
						<InputText
							label={`${t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.email.label')
							})} *`}
							name='email'
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.email.placeholder')
							})}
							required={true}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								setMarketers({
									...marketers,
									data: {
										...marketers.data,
										contact: {
											...marketers.data?.contact,
											email: target.value,
										},
									},
								});
							}}
						/>
						<InputText
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.mobile.label')
							})}
							name='mobile'
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.mobile.placeholder')
							})}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								setMarketers({
									...marketers,
									data: {
										...marketers.data,
										contact: {
											...marketers.data?.contact,
											mobile: target.value,
										},
									},
								});
							}}
						/>
					</div>
				</div>
				<div className={styles.col}>
					<div className={styles.form_elements}>
						<div className={styles.title}>{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.modal.address.title')
						})}</div>
						<div className={styles.form_row}>
							<InputText
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.street_number.label')
								})}
								name='street_number'
								placeholder={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.street_number.placeholder')
								})}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									const target = event.target as HTMLInputElement;
									setMarketers({
										...marketers,
										data: {
											...marketers.data,
											contact: {
												...marketers.data?.contact,
												street_number: target.value,
											},
										},
									});
								}}
							/>
							<InputText
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.street_type.label')
								})}
								name='street_type'
								placeholder={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.street_type.placeholder')
								})}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									const target = event.target as HTMLInputElement;
									setMarketers({
										...marketers,
										data: {
											...marketers.data,
											contact: {
												...marketers.data?.contact,
												street_type: target.value,
											},
										},
									});
								}}
							/>
						</div>
						<InputText
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.address.street_name.label')
							})}
							name='street_name'
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.address.street_name.placeholder')
							})}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								setMarketers({
									...marketers,
									data: {
										...marketers.data,
										contact: {
											...marketers.data?.contact,
											street_name: target.value,
										},
									},
								});
							}}
						/>
						<div className={styles.form_row}>
							<InputText
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.postal_code.label')
								})}
								name='postal_code'
								placeholder={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.postal_code.placeholder')
								})}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									const target = event.target as HTMLInputElement;
									setMarketers({
										...marketers,
										data: {
											...marketers.data,
											contact: {
												...marketers.data?.contact,
												postal_code: target.value,
											},
										},
									});
								}}
							/>
							<InputText
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.city.label')
								})}
								name='city'
								placeholder={t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.modal.address.city.placeholder')
								})}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									const target = event.target as HTMLInputElement;
									setMarketers({
										...marketers,
										data: {
											...marketers.data,
											contact: {
												...marketers.data?.contact,
												city: target.value,
											},
										},
									});
								}}
							/>
						</div>
						<StatusBanner
							message={t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.modal.status_banner')
							})}
							theme={EnumStatusTheme.INFO}
						/>
					</div>
				</div>
				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						disabled={isDisabled}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.save')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</div>
		</Form>
	);
};

export default ModalOffersContact;
