import React, {
	ChangeEvent,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import InputDatePicker from '@components/form/input-date-picker';
import Image from '@components/image';

// ASSETS
import infosImage from '@assets/images/lpdipro/illu_recap.png';

// STYLES
import styles from './summary.module.scss';

const Summary = () => {
	const { t } = useTranslation();
	const {
		isDesktopResolution,
		offerState,
		setOfferState
	} : PageOfferContextProps = useOutletContext();

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = String(target.value);
		const name = target.name;
		const formatedDate = new Date(value).toLocaleDateString('fr-FR');
		setOfferState(prevState => ({
			...prevState,
			[name]: formatedDate
		}));
	};

	const inputsSummary = (
		<>
			{/* --------- What is the creation date displayed on the offer?  --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.summary.created_at.title')
				})}
			</div>
			<InputDatePicker
				className={styles.input}
				defaultValue={offerState?.created_at ? offerState.created_at : undefined}
				hasPlaceholder={true}
				iconRight='calendar-day'
				label={`${t('format.capitalize', {
					text: t('page.offers_create_edit.summary.created_at.label')
				})} *`}
				name={'created_at'}
				required={true}
				onChange={handleOnChange}
			/>
			{/* --------- What is the status date displayed on the offer? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.summary.under_promise_at.title')
				})}
			</div>
			<InputDatePicker
				className={styles.input}
				defaultValue={offerState?.under_promise_at ? offerState.under_promise_at : undefined}
				hasPlaceholder={true}
				iconRight='calendar-day'
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.summary.under_promise_at.label')
				})}
				name={'under_promise_at'}
				required={true}
				onChange={handleOnChange}
			/>
			<InputDatePicker
				className={styles.input}
				defaultValue={offerState?.under_option_at ? offerState.under_option_at : undefined}
				hasPlaceholder={true}
				iconRight='calendar-day'
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.summary.under_option_at.label')
				})}
				name={'under_option_at'}
				onChange={handleOnChange}
			/>
			<InputDatePicker
				className={styles.input}
				defaultValue={offerState?.commercialized_at ? offerState.commercialized_at : undefined}
				hasPlaceholder={true}
				iconRight='calendar-day'
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.summary.commercialized_at.label')
				})}
				name={'commercialized_at'}
				onChange={handleOnChange}
			/>
			<InputDatePicker
				className={styles.input}
				defaultValue={offerState?.suspended_at ? offerState.suspended_at : undefined}
				hasPlaceholder={true}
				iconRight='calendar-day'
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.summary.suspended_at.label')
				})}
				name={'suspended_at'}
				onChange={handleOnChange}
			/>
			{/* --------- What is the last update date displayed on the offer? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.summary.updated_at.title')
				})}
			</div>
			<InputDatePicker
				className={styles.input}
				defaultValue={offerState?.updated_at ? offerState.updated_at : undefined}
				hasPlaceholder={true}
				iconRight='calendar-day'
				label={`${t('format.capitalize', {
					text: t('page.offers_create_edit.summary.updated_at.label')
				})} *`}
				name={'updated_at'}
				required={true}
				onChange={handleOnChange}
			/>
		</>
	);

	return (
		<div className={styles.section}>
			<div className={styles.section__left}>
				<div className={styles.step_progress}>
					{t('format.capitalize', {
						text: `${t('page.offers_create_edit.step')} 8/8`
					})}
				</div>
				<div className={styles.intro}>
					{t('format.capitalize', {
						text: `${t('page.offers_create_edit.summary.introduction')} 🚀`
					})}
				</div>
				<div className={styles.legend}>{t('general.form.input.required')}</div>
				<ValidationForm
					isDesktopResolution={isDesktopResolution}
					isValid={offerState.types?.length ? true : false}
					offerState={offerState}
					setOfferState={setOfferState}
				>
					{inputsSummary}
				</ValidationForm>
			</div>
			{isDesktopResolution && (
				<div className={styles.section__right}>
					<Image
						alt='placeholder'
						className={styles.image}
						src={infosImage}
					/>
				</div>
			)}
		</div>
	);
};

export default withTranslation()(Summary);
