import moment from 'moment';
import React, {
	ChangeEvent,
	Dispatch,
	memo,
	MouseEvent,
	ReactNode,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// FETCHES
import PostOfferContactError from '@exceptions/PostOfferContactError';
import PutOfferContactsError from '@exceptions/PutOfferContactsError';
import {
	getSearchContacts,
	postOfferContacts,
	putOfferContacts,
} from '@layouts/Pages/PageOfferEntry/config/fetch';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// HOOKS
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';

// ENUMS
import {
	EnumButtonCorners,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	OfferStateContactsProps,
} from '@layouts/Pages/PageOfferEntry/index';

// ROW ELEMENTS
import LinkElement from './row-elements/link';

// COMPONENTS
import Button from '@components/button';
import InputDatePicker from '@components/form/input-date-picker';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import InputRadioGroup from '@components/form/input-radio-group';
import InputRadio from '@components/form/input-radio-group/input-radio';
import InputText from '@components/form/input-text';
import Textarea from '@components/form/textarea';
import Icon from '@components/icon';
import TooltipModal from '@components/tooltip-modal';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// STYLES
import styles from './contact-row.module.scss';

interface ContactRowProps {
	canSelect?: boolean;
	checkboxElement?: ReactNode;
	className?: string;
	currentOfferId: number;
	error: boolean;
	isDesktopResolution: boolean;
	isSelected?: boolean;
	offerContact: ContactOffer;
	offerStateContacts: OfferStateContactsProps;
	onClickRow?: (rowId: number) => void;
	rowId?: number;
	selectedRowId?: number;
	setError: Dispatch<SetStateAction<boolean>>;
	setIsOpenModal: Dispatch<SetStateAction<boolean>>;
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
}

export interface IsOpenState {
  comment: boolean;
  identity: boolean;
  link: boolean;
  mandate: boolean;
  mandateNumber: boolean;
  receipt: boolean;
  ref: boolean;
  role: boolean;
}

export interface InputLinkState {
	value: string | undefined;
	type: 'web_reference' | 'web_title' | undefined;
}

const ContactRow = memo(({
	canSelect,
	checkboxElement,
	className,
	currentOfferId,
	error,
	isDesktopResolution,
	isSelected,
	offerContact,
	offerStateContacts,
	onClickRow,
	rowId,
	selectedRowId,
	setError,
	setIsOpenModal,
	setOfferStateContacts,
}: ContactRowProps): JSX.Element => {
	const { t } = useTranslation();

	const cssClasses = [
		styles.contact_row
	];

	const [
		activeElement,
		setActiveElement
	] = useState<string | null>(null);

	const [
		searchContacts,
		setSearchContacts
	] = useState([
	] as ContactOffer[]);

	const [
		query,
		setQuery
	] = useState(undefined);

	const [
		inputValue,
		setInputValue
	] = useState<string>(undefined);

	const [
		isTooltipOpen,
		setIsTooltipOpen
	] = useState<IsOpenState>({
		identity: false,
		role: false,
		mandate: false,
		mandateNumber: false,
		ref: false,
		receipt: false,
		link: false,
		comment: false,
	});

	useEffect(() => {
		const fetchSearchContacts = async () => {
			return await getSearchContacts(query).then(responseParsed => {
				if (responseParsed.status === 200) {
					if (offerStateContacts?.contacts?.collection?.length > 0) {
						const existingContactIds = offerStateContacts.contacts.collection.map(contact => contact.contact_id);
						const filteredContacts = responseParsed.payload.collection.filter((contact: ContactOffer) => {
							return !existingContactIds.includes(contact.contact_id);
						});
						setSearchContacts(filteredContacts);
					} else {
						setSearchContacts(responseParsed.payload.collection);
					}
				}
			});
		};
		fetchSearchContacts();
	}, [
		query
	]);

	useDebouncedEffect(() => {
		if (inputValue?.length >= 3) {
			setQuery(inputValue);
		} else {
			setQuery(undefined);
		}
	}, [
		inputValue
	], APP_CONF_VARS.timeout.debounce);

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.contact_row__can_select);
	if (isSelected) cssClasses.push(styles.contact_row__selected);
	if (error) cssClasses.push(styles.contact_row__error);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		if (onClickRow && offerContact?.id) onClickRow(offerContact?.id);
		const id = event.currentTarget.id;
		setActiveElement(prev => (prev === id ? null : id));
	};

	const handleSetIsOpen = useCallback((key: keyof IsOpenState) => (isOpen: boolean) => {
		setIsTooltipOpen(prevState => ({
			...prevState,
			[key]: isOpen
		}));
	}, [
		setIsTooltipOpen
	]);

	{/* --------- Contact Identity --------- */}
	const searchOptions = searchContacts.length > 0 ? searchContacts.map((searchContact) => {
		const newSearchContact = new ContactOffer(searchContact);
		const userCardDataSearch = {
			title: newSearchContact?.full_name,
			id: newSearchContact?.contact_id?.toString(),
			image_url: newSearchContact.logo?.url,
			descriptions: [
				{
					value: newSearchContact?.company_name,
					type: 'subtitle'
				},
			],
		};
		return {
			value: newSearchContact,
			inputValue: {
				label: newSearchContact.full_name
					? `${newSearchContact.full_name}${newSearchContact.company_name ? ` - ${newSearchContact.company_name}` : ''}`
					: newSearchContact.company_name,
				img: newSearchContact.logo,
			},
			label: newSearchContact.is_default ? (
				<div className={styles.mandat_pending}>
					<span>?</span>
					{t('format.capitalize', {
						text: t('general.awaiting_mandate')
					})}
				</div>
			) : (
				<UserCard
					data={userCardDataSearch}
					theme={ThemeUserCard.SQUARE}
				/>
			),
		};
	}) : [
	];

	const userCardData = {
		title: offerContact?.full_name,
		id: offerContact?.id?.toString(),
		image_url: offerContact?.logo?.url,
		descriptions: [
			{
				value: offerContact?.company_name,
				type: 'subtitle'
			},
		],
	};

	const inputSearchElement = (
		<div className={styles.input_search_container}>
			<InputMultiSelect
				defaultValue={offerContact ?
					{
						label: offerContact.full_name,
						value: offerContact,
						inputValue: {
							label: offerContact.full_name,
							img: offerContact.logo,
						},
					} : undefined
				}
				isMulti={false}
				maxMenuHeight={230}
				menuIsOpen={true}
				name={'name'}
				noOptionsMessage={() => (
					<div className={styles.no_options_message}>
						<Icon
							className={styles.icon}
							fontStyle={EnumFontStyle.LIGHT}
							name='search-slash'
						/>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.no_results_message')
						})}
					</div>
				)}
				options={searchOptions}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.identity.add_contact')
				})}
				onChange={async (selected: OptionType | OptionType[]) => {
					setIsTooltipOpen({
						...isTooltipOpen,
						identity: false,
					});

					const newContact = Array.isArray(selected) ? selected[0]?.value as ContactOffer : selected?.value as ContactOffer;

					let newContactId: number;
					if (offerContact?.id) {
						const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
							contact_id: newContact.contact_id,
						});
						handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
						if (putOfferContactsResponse.status === 200) {
							newContactId = offerContact.id;
							setError(false);
						} else {
							setError(true);
						}
					} else {
						const postOfferContactsResponse = await postOfferContacts(currentOfferId, {
							contact_id: newContact.contact_id,
						});
						handleErrorResponse(postOfferContactsResponse, PostOfferContactError);
						if (postOfferContactsResponse.status === 200) {
							newContactId = postOfferContactsResponse.payload.id;
							setError(false);
						} else {
							setError(true);
						}
					}

					const createNewContactObject = () => ({
						company_name: newContact?.company_name,
						contact_id: newContact?.contact_id,
						firstname: newContact?.firstname,
						id: newContactId,
						is_default: newContact?.is_default,
						is_mandated: newContact?.is_mandated,
						lastname: newContact?.lastname,
						logo: newContact?.logo,
						mandate_number: newContact?.mandate_number,
						original_mail_date: newContact?.original_mail_date,
						reference: newContact?.reference,
						role: newContact?.role,
						web_reference: newContact?.web_reference,
					});

					const existingContacts = offerStateContacts?.contacts?.collection || [
					];

					let newContactList;

					if (existingContacts.length === 0) {
						newContactList = [
							createNewContactObject()
						];
					} else if (rowId === undefined || rowId >= existingContacts.length) {
						newContactList = [
							...existingContacts,
							createNewContactObject()
						];
					} else {
						newContactList = existingContacts.map((contact, index) =>
							index === rowId ? createNewContactObject() : contact
						);
					}

					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: newContactList,
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}}
				onInputChange={(newValue: string) => {
					if (newValue) {
						setInputValue(newValue);
					}
				}}
			/>
			<div className={styles.button_container}>
				<Button
					corners={EnumButtonCorners.Square}
					hasBoxShadow={false}
					iconLeft={'plus'}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.identity.create_contact')
					})}
					theme={EnumTheme.NAKED}
					onClick={() => {
						setIsTooltipOpen({
							...isTooltipOpen,
							identity: false,
						});
						setIsOpenModal(true);
					}}
				/>
			</div>
		</div>
	);

	const contactIdentityElement = (
		<div
			className={`${styles.cell_identity} ${activeElement === 'identity' && selectedRowId === offerContact?.id ? styles.active : ''}`}
			id={`identity_${rowId}`}
			onClick={handleOnClick}
		>
			<TooltipModal
				className={styles.tooltip_modal_contact_identity}
				clickable={true}
				htmlElement={inputSearchElement}
				id={`tooltip_identity_${rowId}`}
				isOpen={isTooltipOpen.identity}
				offset={1}
				openOnClick={true}
				place={EnumTooltipPosition.BOTTOM_START}
				setIsOpen={handleSetIsOpen('identity')}
			>
				<span className={styles.tooltip_modal_contact_identity_content}>
					{offerContact ? (
						offerContact.is_default ? (
							<div className={styles.mandat_pending}>
								<span>?</span>
								{t('format.capitalize', {
									text: t('general.awaiting_mandate')
								})}
							</div>
						) : (
							<UserCard
								data={userCardData}
								theme={ThemeUserCard.SQUARE}
							/>
						)) : error ?
						(
							<span className={styles.error_message}>
								<Icon
									className={styles.icon}
									fontStyle={EnumFontStyle.LIGHT}
									name="exclamation-triangle"
								/>
								{t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.identity.error_message')
								})}
							</span>
						)
						: t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.identity.add_contact')
						})}
				</span>
			</TooltipModal>
		</div>
	);

	{/* --------- Role --------- */}
	const roleElement = (
		<div
			className={`${styles.cell_role} ${activeElement === 'role' && selectedRowId === offerContact?.id ? styles.active : ''} ${styles.not_allowed}`}
			id="role"
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_role')
				})} :</span>
			)}
			<span className={styles.tooltip_modal_role_content}>
				{offerContact?.id ? offerContact.role ? t('format.capitalize', {
					text: t(`page.offers_create_edit.contacts.role.${offerContact.role}`)
				}) : t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.role.unknown')
				}) : (
					<span className={styles.placeholder}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.role.placeholder')
						})}
					</span>
				)}
			</span>
		</div>
	);

	{/* --------- Mandate --------- */}
	const mandateOptions = [
		{
			label: t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.mandate.with')
			}),
			value: 'true',
			name: `width${rowId}`,
			id: `width${rowId}`,
		},
		{
			label: t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.mandate.without')
			}),
			value: 'false',
			name: `without${rowId}`,
			id: `without${rowId}`,
		}
	];
	const inputMandateElement = (
		<InputRadioGroup
			label={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.with_mandate')
			})}
			name={`mandate_${rowId}`}
			selectedIndex={offerContact?.is_mandated ? 0 : 1}
			onChange={async (event: ChangeEvent<HTMLInputElement>) => {
				const target = event.target as HTMLInputElement;
				const value = target.value === 'true' ? true : false;
				setOfferStateContacts({
					...offerStateContacts,
					contacts: {
						...offerStateContacts?.contacts,
						collection: offerStateContacts?.contacts?.collection.map(contact =>
							contact.id === offerContact.id
								? {
									...contact,
									is_mandated: value
								}
								: contact
						),
					} as Collection<ContactOfferJson, ContactOffer>,
				});
				const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
					comment: offerContact?.comment,
					contact_id: offerContact.contact_id,
					is_mandated: value,
					mandate_number: offerContact?.mandate_number,
					original_mail_date: offerContact?.original_mail_date,
					reference: offerContact?.reference,
					web_reference: offerContact?.web_reference,
				});
				handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
				if (putOfferContactsResponse.status !== 200) {
					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: offerStateContacts?.contacts?.collection.map(contact =>
								contact.id === offerContact.id
									? {
										...contact,
										is_mandated: !value
									}
									: contact
							),
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}
			}}
		>
			{mandateOptions.map((option, key) => {
				return (
					<InputRadio
						{...option}
						key={key}
					/>
				);
			})}
		</InputRadioGroup>
	);

	const mandateElement = (
		<div
			className={`${styles.cell_mandate}
			${activeElement === 'mandate' && selectedRowId === offerContact?.id ? styles.active : ''}
			${!offerContact?.id || offerContact?.role !== 'broker' ? styles.not_allowed : ''}`}
			id={`mandate_${rowId}`}
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span>{t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_mandate')
				})} :</span>
			)}
			{offerContact?.id ? offerContact?.role !== 'broker' ? (
				<span className={styles.tooltip_modal_receipt_content}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.mandate.without')
					})}
				</span>
			)  : (
				<TooltipModal
					className={styles.tooltip_modal_mandate}
					clickable={true}
					disabled={!offerContact?.id}
					htmlElement={inputMandateElement}
					id={`tooltip_mandate_${rowId}`}
					isOpen={isTooltipOpen.mandate}
					offset={1}
					openOnClick={true}
					place={EnumTooltipPosition.BOTTOM_START}
					setIsOpen={handleSetIsOpen('mandate')}
				>
					<span className={styles.tooltip_modal_mandate_content}>
						{offerContact?.is_mandated ? t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.mandate.with')
						}) : t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.mandate.without')
						})}
					</span>
				</TooltipModal>
			) : (
				<span className={styles.tooltip_modal_mandate_content}>
					<span className={styles.placeholder}>{t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.mandate.placeholder')
					})}</span>
				</span>
			)}
		</div>
	);

	{/* --------- Mandate Number --------- */}
	const [
		inputValueMandateNumber,
		setInputValueMandateNumber
	] = useState<string>(undefined);

	const handleChangeMandateNumber = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		setInputValueMandateNumber(value);
	};

	useDebouncedEffect(() => {
		const updateMandateNumber = async () => {
			const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
				comment: offerContact?.comment,
				contact_id: offerContact?.contact_id,
				is_mandated: offerContact?.is_mandated,
				mandate_number: inputValueMandateNumber,
				original_mail_date: offerContact?.original_mail_date,
				reference: offerContact?.reference,
				web_reference: offerContact?.web_reference,
			});

			handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
			if (putOfferContactsResponse.status === 200) {
				setOfferStateContacts({
					...offerStateContacts,
					contacts: {
						...offerStateContacts?.contacts,
						collection: offerStateContacts?.contacts?.collection.map(contact =>
							contact.id === offerContact.id
								? {
									...contact,
									mandate_number: inputValueMandateNumber
								}
								: contact
						),
					} as Collection<ContactOfferJson, ContactOffer>,
				});
			}
		};

		if (inputValueMandateNumber !== undefined) {
			updateMandateNumber();
		}
	}, [
		inputValueMandateNumber
	], APP_CONF_VARS.timeout.debounce);

	const inputMandateNumberElement = (
		<InputText
			defaultValue={offerContact?.mandate_number}
			name={`mandate_number_${rowId}`}
			placeholder={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.mandate_number.input_placeholder')
			})}
			value={offerContact?.mandate_number}
			onChange={handleChangeMandateNumber}
		/>
	);

	const mandateNumberElement = (
		<div
			className={`
				${styles.cell_mandate_number}
				${activeElement === 'mandate_number' && selectedRowId === offerContact?.id ? styles.active : ''}
				${!offerContact?.id ? styles.not_allowed : ''}`}
			id={`mandate_number_${rowId}`}
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_mandate_number')
				})} :</span>
			)}

			<TooltipModal
				className={styles.tooltip_modal_mandate_number}
				clickable={true}
				disabled={!offerContact?.id}
				htmlElement={inputMandateNumberElement}
				id={`tooltip_mandate_number_${rowId}`}
				isOpen={isTooltipOpen.mandateNumber}
				offset={1}
				openOnClick={true}
				place={EnumTooltipPosition.BOTTOM_START}
				setIsOpen={handleSetIsOpen('mandateNumber')}
			>
				<span className={styles.tooltip_modal_mandate_number_content}>
					{offerContact?.mandate_number ? offerContact.mandate_number : (
						<span className={styles.placeholder}>{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.mandate_number.placeholder')
						})}</span>
					)}
				</span>
			</TooltipModal>
		</div>
	);

	{/* --------- Ref de l'offre--------- */}
	const [
		inputValueRef,
		setInputValueRef
	] = useState<string>(undefined);

	const handleChangeRef = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		setInputValueRef(value);
	};

	useDebouncedEffect(() => {
		const updateRef = async () => {

			if (offerContact?.id) {
				const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
					reference: inputValueRef,
					contact_id: offerContact.contact_id,
					is_mandated: offerContact.is_mandated,
					mandate_number: offerContact.mandate_number,
					original_mail_date: offerContact.original_mail_date,
					comment: offerContact.comment,
					web_reference: offerContact.web_reference,
				});
				handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
				if (putOfferContactsResponse.status === 200) {
					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: offerStateContacts?.contacts?.collection.map(contact =>
								contact.id === offerContact.id
									? {
										...contact,
										reference: inputValueRef
									}
									: contact
							),
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}
			}
		};

		if (inputValueRef !== undefined) {
			updateRef();
		}
	}, [
		inputValueRef
	], APP_CONF_VARS.timeout.debounce);

	const inputRefElement = (
		<InputText
			defaultValue={offerContact?.reference}
			name={`ref_${rowId}`}
			placeholder={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.ref.input_placeholder')
			})}
			value={offerContact?.reference}
			onChange={handleChangeRef}
		/>
	);

	const refOfferElement = (
		<div
			className={`
				${styles.cell_ref}
				${activeElement === 'ref' && selectedRowId === offerContact?.id ? styles.active : ''}
				${!offerContact?.id ? styles.not_allowed : ''}
			`}
			id={`ref_${rowId}`}
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_ref')
				})} :</span>
			)}
			<TooltipModal
				className={styles.tooltip_modal_ref}
				clickable={true}
				disabled={!offerContact?.id}
				htmlElement={inputRefElement}
				id={`tooltip_ref_${rowId}`}
				isOpen={isTooltipOpen.ref}
				offset={1}
				openOnClick={true}
				place={EnumTooltipPosition.BOTTOM_START}
				setIsOpen={handleSetIsOpen('ref')}
			>
				<span className={styles.tooltip_modal_ref_content}>
					{offerContact?.reference ? offerContact.reference : (
						<span className={styles.placeholder}>{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.ref.placeholder')
						})}</span>
					)}
				</span>
			</TooltipModal>
		</div>
	);

	{/* --------- Receipt Date --------- */}
	const inputReceiptElement = (
		<>
			<InputDatePicker
				className={styles.input_date_picker}
				id={`receipt_date_${rowId}`}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.receipt.date.label')
				})}
				name={`receipt_date_${rowId}`}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.receipt.date.placeholder')
				})}
				value={offerContact?.original_mail_date}
				onChange={async (date: ChangeEvent<HTMLInputElement>) => {
					const newDate = moment(date.target.value);
					const currentDateTime = moment(offerContact.original_mail_date);
					const updatedDateTime = currentDateTime
						.year(newDate.year())
						.month(newDate.month())
						.date(newDate.date());
					const formattedDate = updatedDateTime.toISOString();
					const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
						original_mail_date: formattedDate,
						contact_id: offerContact.contact_id,
					});
					handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
					if (putOfferContactsResponse.status === 200) {
						setOfferStateContacts({
							...offerStateContacts,
							contacts: {
								...offerStateContacts?.contacts,
								collection: offerStateContacts?.contacts?.collection.map(contact =>
									contact.id === offerContact.id
										? {
											...contact,
											original_mail_date: formattedDate
										}
										: contact
								),
							} as Collection<ContactOfferJson, ContactOffer>,
						});
					}
				}
				}
			/>
			<InputText
				iconRight={'clock'}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.receipt.time.label')
				})}
				name={`receipt_time_${rowId}`}
				required={false}
				type={EnumInputType.TIME}
				onChange={async (date: ChangeEvent<HTMLInputElement>) => {
					const newTime = date.target.value;
					const currentDate = offerContact?.original_mail_date
						? new Date(offerContact.original_mail_date)
						: new Date();
					const [
						hours,
						minutes
					] = newTime.split(':').map(Number);
					currentDate.setHours(hours, minutes);
					const updatedDateString = currentDate.toISOString();
					const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
						original_mail_date: updatedDateString,
						contact_id: offerContact.contact_id,
					});
					handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
					if (putOfferContactsResponse.status === 200) {
						setOfferStateContacts({
							...offerStateContacts,
							contacts: {
								...offerStateContacts?.contacts,
								collection: offerStateContacts?.contacts?.collection.map(contact =>
									contact.id === offerContact.id
										? {
											...contact,
											original_mail_date: updatedDateString
										}
										: contact
								),
							} as Collection<ContactOfferJson, ContactOffer>,
						});
					}
				}
				}
			/>
		</>
	);

	const formatDate = (dateString: string): string => {
		if (!dateString) return '';
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear().toString().slice(-2);
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${day}/${month}/${year}\nà ${hours}:${minutes}`;
	};

	const receiptDateDesktop = (
		<div
			className={`${styles.cell_receipt}
			${activeElement === 'receipt' && selectedRowId === offerContact?.id ? styles.active : ''}
			${!offerContact?.id ? styles.not_allowed : ''}
		`}
			id={`receipt_${rowId}`}
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_receipt')
				})} :</span>
			)}
			<TooltipModal
				className={styles.tooltip_modal_receipt}
				clickable={true}
				disabled={!offerContact?.id}
				htmlElement={inputReceiptElement}
				id={`tooltip_receipt_${rowId}`}
				isOpen={isTooltipOpen.receipt}
				offset={1}
				openOnClick={true}
				place={EnumTooltipPosition.BOTTOM_START}
				setIsOpen={handleSetIsOpen('receipt')}
			>
				<span className={styles.tooltip_modal_receipt_content}>
					{offerContact?.original_mail_date ? formatDate(offerContact.original_mail_date) : (
						<span className={styles.placeholder}>{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.receipt.placeholder')
						})}</span>
					)}
				</span>
			</TooltipModal>
		</div>
	);

	{/* --------- Comment --------- */}
	const [
		inputValueComment,
		setInputValueComment
	] = useState<string>(undefined);

	const handleChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const target = event.target as HTMLTextAreaElement;
		const value = target.value;
		setInputValueComment(value);
	};

	useDebouncedEffect(() => {
		const updateComment = async () => {
			if (offerContact?.id) {
				const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
					comment: inputValueComment,
					is_mandated: offerContact.is_mandated,
					mandate_number: offerContact.mandate_number,
					original_mail_date: offerContact.original_mail_date,
					reference: offerContact?.reference,
					web_reference: offerContact.web_reference,
					contact_id: offerContact.contact_id,
				});
				handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
				if (putOfferContactsResponse.status === 200) {
					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: offerStateContacts?.contacts?.collection.map(contact =>
								contact.id === offerContact.id
									? {
										...contact,
										comment: inputValueComment
									}
									: contact
							),
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}
			}
		};

		if (inputValueComment !== undefined) {
			updateComment();
		}
	}, [
		inputValueComment
	], APP_CONF_VARS.timeout.debounce);
	const inputCommentElement = (
		<Textarea
			defaultValue={offerContact?.comment}
			label={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.comment.label')
			})}
			name={`comment_${rowId}`}
			placeholder={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.comment.input_placeholder')
			})}
			value={offerContact?.comment}
			onChange={(event: ChangeEvent<HTMLTextAreaElement>) => handleChangeComment(event)}
		/>
	);
	const commentElement = (
		<div
			className={`
				${styles.cell_comment}
				${activeElement === 'comment' && selectedRowId === offerContact?.id ? styles.active : ''}
				${!offerContact?.id ? styles.not_allowed : ''}
			`}
			id={`comment_${rowId}`}
			onClick={handleOnClick}
		>
			{isDesktopResolution ? undefined : (
				<span> {t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.header_table_comment')
				})} :</span>
			)}
			<TooltipModal
				className={styles.tooltip_modal_comment}
				clickable={true}
				disabled={!offerContact?.id}
				htmlElement={inputCommentElement}
				id={`tooltip_comment_${rowId}`}
				isOpen={isTooltipOpen.comment}
				offset={1}
				openOnClick={true}
				place={EnumTooltipPosition.BOTTOM}
				setIsOpen={handleSetIsOpen('comment')}
			>
				<span className={styles.tooltip_modal_comment_content}>
					{offerContact?.comment ? offerContact.comment : (
						<span className={styles.placeholder}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.comment.input_placeholder')
							})}
						</span>
					)}
				</span>
			</TooltipModal>
		</div>
	);

	return (
		<div
			className={cssClasses.join(' ')}
		>
			{canSelect ? <div className={styles.selection}>{offerContact?.id ? checkboxElement : undefined}</div> : null}
			{contactIdentityElement}
			{roleElement}
			{mandateElement}
			{mandateNumberElement}
			{refOfferElement}
			{receiptDateDesktop}
			<LinkElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
			{commentElement}
		</div>
	);
});

export default ContactRow;
