import React from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// ENUMS
import {
	EnumInputTheme,
	EnumInputType,
} from '@enums/form.enum';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	findDefaultOption,
	generateOptions,
	handleOnChange,
	handleOptionChange,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Form from '@components/form';
import InputCheckbox from '@components/form/input-checkbox';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import InputText from '@components/form/input-text';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import Textarea from '@components/form/textarea';
import Image from '@components/image';

// ASSETS
import infosImage from '@assets/images/lpdipro/illu_mens.png';

// STYLES
import styles from './terms.module.scss';

const Terms = () => {
	const { t } = useTranslation();
	const {
		offerState,
		setOfferState,
		isDesktopResolution,
		offerEnumsState,
	} : PageOfferContextProps = useOutletContext();

	const inputsSummary = (
		<Form>
			{/* --------- What are the transfer rights and entry fees? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.rental_cession_price.title')
				})}
			</div>
			<InputTextWithUnit
				className={styles.input}
				defaultValue={offerState?.rental_cession_price ? offerState.rental_cession_price.toString() : undefined}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.rental_cession_price.label')
				})}
				name={'rental_cession_price'}
				type={EnumInputType.NUMBER}
				unit={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.rental_cession_price.unit')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<InputTextWithUnit
				className={styles.input}
				defaultValue={offerState?.entrance_fee ? offerState.entrance_fee.toString() : undefined}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.entrance_fee.label')
				})}
				name={'entrance_fee'}
				type={EnumInputType.NUMBER}
				unit={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.entrance_fee.unit')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>

			{/* --------- What are the taxes? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.office_tax.title')
				})}
			</div>
			<InputCheckbox
				checked={offerState?.show_office_tax}
				id={'show_office_tax'}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.office_tax.toggle')
				})}
				name={'show_office_tax'}
				theme={EnumInputTheme.TOGGLE}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<Textarea
				defaultValue={offerState?.office_tax}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.office_tax.label')
				})}
				name={'office_tax'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.office_tax.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<InputCheckbox
				checked={offerState?.show_property_tax}
				id={'show_property_tax'}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.property_tax.toggle')
				})}
				name={'show_property_tax'}
				theme={EnumInputTheme.TOGGLE}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<Textarea
				defaultValue={offerState?.property_tax}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.property_tax.label')
				})}
				name={'property_tax'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.property_tax.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<InputCheckbox
				checked={offerState?.show_teom}
				id={'show_teom'}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.teom.toggle')
				})}
				name={'show_teom'}
				theme={EnumInputTheme.TOGGLE}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<Textarea
				defaultValue={offerState?.teom}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.teom.label')
				})}
				name={'teom'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.teom.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<InputCheckbox
				checked={offerState?.show_professional_tax}
				id={'show_professional_tax'}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.profession_tax.toggle')
				})}
				name={'show_professional_tax'}
				theme={EnumInputTheme.TOGGLE}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<Textarea
				defaultValue={offerState?.profession_tax}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.profession_tax.label')
				})}
				name={'profession_tax'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.profession_tax.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<Textarea
				defaultValue={offerState?.profession_tax_rate}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.profession_tax_rate.label')
				})}
				name={'profession_tax_rate'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.profession_tax_rate.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>

			{/* --------- Any comment to report on taxes? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.tax_comment.title')
				})}
			</div>
			<Textarea
				defaultValue={offerState?.tax_comment}
				name={'tax_comment'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.tax_comment.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>

			{/* --------- What are the management fees? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.management_fees_value.title')
				})}
			</div>
			<div className={styles.form_row}>
				<InputText
					defaultValue={offerState?.management_fees_value}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.management_fees_value.label')
					})}
					name={'management_fees_value'}
					placeholder={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.management_fees_value.placeholder')
					})}
					type={EnumInputType.NUMBER}
					onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
				/>
				<InputText
					defaultValue={offerState?.management_fees_unit}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.management_fees_unit.label')
					})}
					name={'management_fees_unit'}
					placeholder={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.management_fees_unit.placeholder')
					})}
					onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
				/>
			</div>

			{/* --------- Are there any other fees? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.quote_part_rie.title')
				})}
			</div>
			<InputTextWithUnit
				className={styles.input}
				defaultValue={offerState?.quote_part_rie}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.quote_part_rie.label')
				})}
				name={'quote_part_rie'}
				type={EnumInputType.NUMBER}
				unit={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.quote_part_rie.unit')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}

			/>
			<InputTextWithUnit
				className={styles.input}
				defaultValue={offerState?.deed_fees}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.deed_fees.label')
				})}
				name={'deed_fees'}
				type={EnumInputType.NUMBER}
				unit={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.deed_fees.unit')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>

			{/* --------- What are the agency fees? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.rental_fees.title')
				})}
			</div>
			<div className={styles.form_row}>
				<InputTextWithUnit
					className={styles.input}
					defaultValue={offerState?.rental_fees ? offerState.rental_fees.toString() : undefined}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.rental_fees.label')
					})}
					name='rental_fees'
					type={EnumInputType.NUMBER}
					unit={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.rental_fees.unit')
					})}
					onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
				/>
				<InputMultiSelect
					defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_rental_fees_units), offerState?.ref_rental_fees_unit_id)}
					hasShadowMenu={true}
					isMulti={false}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.ref_rental_fees_unit_id.label')
					})}
					maxMenuHeight={150}
					name={'ref_rental_fees_unit_id'}
					options={generateOptions(offerEnumsState?.ref_rental_fees_units)}
					placeholder={t('format.capitalize', {
						text: t('general.form.input.placeholder')
					})}
					onChange={(selected: OptionType) => {
						handleOptionChange('ref_rental_fees_unit_id', offerState, selected, 'terms', setOfferState);
					}}
				/>
			</div>
			<InputCheckbox
				checked={offerState?.is_rental_fees_charged_to_the_lessee}
				id={'is_rental_fees_charged_to_the_lessee'}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.is_rental_fees_charged_to_the_lessee.toggle')
				})}
				name={'is_rental_fees_charged_to_the_lessee'}
				theme={EnumInputTheme.TOGGLE}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<div className={styles.form_row}>
				<InputTextWithUnit
					className={styles.input}
					defaultValue={offerState?.sale_fees ? offerState.sale_fees.toString() : undefined}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.sale_fees.label')
					})}
					name={'sale_fees'}
					type={EnumInputType.NUMBER}
					unit={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.sale_fees.unit')
					})}
					onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
				/>
				<InputMultiSelect
					defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_sale_fees_units), offerState?.sale_ref_sale_fees_unit_id)}
					hasShadowMenu={true}
					isMulti={false}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.sale_ref_sale_fees_unit_id.label')
					})}
					maxMenuHeight={150}
					name={'sale_ref_sale_fees_unit_id'}
					options={generateOptions(offerEnumsState?.ref_sale_fees_units)}
					placeholder={t('format.capitalize', {
						text: t('general.form.input.placeholder')
					})}
					onChange={(selected: OptionType) => {
						handleOptionChange('sale_ref_sale_fees_unit_id', offerState, selected, 'terms', setOfferState);
					}}
				/>
			</div>
			<InputCheckbox
				checked={offerState?.is_sale_fees_charged_to_the_buyer}
				id={'is_sale_fees_charged_to_the_buyer'}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.is_sale_fees_charged_to_the_buyer.toggle')
				})}
				name={'is_sale_fees_charged_to_the_buyer'}
				theme={EnumInputTheme.TOGGLE}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>

			{/* --------- Any other characteristics to report on fees? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.fees_comment.title')
				})}
			</div>
			<Textarea
				defaultValue={offerState?.fees_comment}
				name={'fees_comment'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.fees_comment.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>

			{/* --------- What are the legal conditions? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.terms.ref_announcement_payment_type_id.title')
				})}
			</div>
			<InputMultiSelect
				className={styles.input}
				defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_announcement_payment_types), offerState?.ref_announcement_payment_type_id)}
				hasShadowMenu={true}
				isMulti={false}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.ref_announcement_payment_type_id.label')
				})}
				maxMenuHeight={150}
				name={'ref_announcement_payment_type_id'}
				options={generateOptions(offerEnumsState?.ref_announcement_payment_types)}
				placeholder={t('format.capitalize', {
					text: t('general.form.input.placeholder')
				})}
				onChange={(selected: OptionType) => {
					handleOptionChange('ref_announcement_payment_type_id', offerState, selected, 'terms', setOfferState);
				}}
			/>
			<InputTextWithUnit
				className={styles.input}
				defaultValue={offerState?.rental_caution}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.rental_caution.label')
				})}
				name={'rental_caution'}
				unit={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.rental_caution.unit')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<InputMultiSelect
				className={styles.input}
				defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_announcement_indexations), offerState?.ref_announcement_indexation_id)}
				hasShadowMenu={true}
				isMulti={false}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.ref_announcement_indexation_id.label')
				})}
				maxMenuHeight={150}
				name={'ref_announcement_indexation_id'}
				options={generateOptions(offerEnumsState?.ref_announcement_indexations)}
				placeholder={t('format.capitalize', {
					text: t('general.form.input.placeholder')
				})}
				onChange={(selected: OptionType) => {
					handleOptionChange('ref_announcement_indexation_id', offerState, selected, 'terms', setOfferState);
				}}
			/>
			<InputText
				className={styles.input}
				defaultValue={offerState?.annual_indexation}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.annual_indexation.label')
				})}
				name={'annual_indexation'}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<InputMultiSelect
				defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_fiscal_regimes), offerState?.ref_fiscal_regime_id)}
				hasShadowMenu={true}
				isMulti={false}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.ref_fiscal_regime_id.label')
				})}
				maxMenuHeight={150}
				name={'ref_fiscal_regime_id'}
				options={generateOptions(offerEnumsState?.ref_fiscal_regimes)}
				placeholder={t('format.capitalize', {
					text: t('general.form.input.placeholder')
				})}
				onChange={(selected: OptionType) => {
					handleOptionChange('ref_fiscal_regime_id', offerState, selected, 'terms', setOfferState);
				}}
			/>
			<InputText
				defaultValue={offerState?.fiscal_regime}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.fiscal_regime.label')
				})}
				name={'fiscal_regime'}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.fiscal_regime.placeholder')
				})}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<div className={styles.form_row}>
				<InputTextWithUnit
					defaultValue={offerState?.security_deposit}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.security_deposit.label')
					})}
					name={'security_deposit'}
					type={EnumInputType.NUMBER}
					unit={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.security_deposit.unit')
					})}
					onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
				/>
				<InputMultiSelect
					defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_price_units), offerState?.security_deposit_ref_price_unit_id)}
					hasShadowMenu={true}
					isMulti={false}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.terms.security_deposit_ref_price_unit_id.label')
					})}
					maxMenuHeight={150}
					name={'security_deposit_ref_price_unit_id'}
					options={generateOptions(offerEnumsState?.ref_price_units)}
					onChange={(selected: OptionType) => {
						handleOptionChange('security_deposit_ref_price_unit_id', offerState, selected, 'terms', setOfferState);
					}}
				/>
			</div>
			<InputMultiSelect
				defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_lease_activities), offerState?.ref_lease_activity)}
				hasShadowMenu={true}
				isMulti={true}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.ref_lease_activity.label')
				})}
				maxMenuHeight={150}
				name={'ref_lease_activity'}
				options={generateOptions(offerEnumsState?.ref_lease_activities)}
				placeholder={t('format.capitalize', {
					text: t('general.form.input.placeholder')
				})}
				onChange={(selected: OptionType) => {
					handleOptionChange('ref_lease_activity', offerState, selected, 'terms', setOfferState);
				}}
			/>
			<Textarea
				defaultValue={offerState?.allowed_activities}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.allowed_activities.label')
				})}
				name={'allowed_activities'}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
			<Textarea
				defaultValue={offerState?.prohibited_activities}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.terms.prohibited_activities.label')
				})}
				name={'prohibited_activities'}
				onChange={(event) => handleOnChange(event, 'terms', setOfferState)}
			/>
		</Form>
	);

	return (
		<div className={styles.section}>
			<div className={styles.section__left}>
				<div className={styles.step_progress}>
					{t('format.capitalize', {
						text: `${t('page.offers_create_edit.step')} 8/8`
					})}
				</div>
				<div className={styles.legend}>{t('general.form.input.required')}</div>
				{inputsSummary}
				<ValidationForm
					isDesktopResolution={isDesktopResolution}
					isValid={true}
					offerState={offerState}
					setOfferState={setOfferState}
				/>
			</div>
			{isDesktopResolution && (
				<div className={styles.section__right}>
					<Image
						alt='placeholder'
						className={styles.image}
						src={infosImage}
					/>
				</div>
			)}
		</div>
	);
};

export default withTranslation()(Terms);
