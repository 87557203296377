import React, {
	ChangeEvent,
	useRef,
	useState,
} from 'react';
import DatePicker from 'react-date-picker';
import {
	Controller,
	ControllerFieldState,
	ControllerRenderProps,
} from 'react-hook-form';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import Icon, {
	IconProps,
} from '@components/icon';
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// MODULES
import formUtils from '@modules/formUtils';

// COMPONENTS
import {
	InputProps,
} from '@components/form/input';
import InputLabel from '@components/form/input-label';
import {
	InputTextProps,
} from '@components/form/input-text';

// STYLING
import styles from './input-date-picker.module.scss';

interface MethodsProps {
	clearErrors?: (name?: string) => void;
	formState?: {
		errors?: {
			[key: string]: string;
			name?: string;
		};
	};
	register?: (name: string, rules: object) => InputDatePickerProps;
	setError?: (name?: string) => void;
}

export interface InputDatePickerProps extends InputTextProps {
	'data-testid'?: string;
	calendarIcon?: IconProps;
	className?: string;
	customError?: string;
	defaultValue?: string;
	disabled?: boolean;
	format?: string;
	hasBorder?: boolean;
	hasCalendarIcon?: boolean;
	hasPlaceholder?: boolean;
	hasShadow?: boolean;
	id?: string;
	invalidDate?: boolean;
	isCalendarOpen?: boolean;
  isOpen?: boolean;
	label?: string;
	language?: string;
	maxDate?: Date;
	methods?: MethodsProps;
	name: string;
	rules?: object;
	value?: string;
}

const InputDatePicker = ({
	'data-testid': dataTestid,
	className,
	customError,
	defaultValue,
	disabled,
	format = 'dd-MM-y',
	hasBorder = true,
	hasCalendarIcon = true,
	hasPlaceholder = false,
	hasShadow = false,
	id,
	innerRef,
	invalidDate,
	isOpen = false,
	label,
	language = APP_CONF_VARS.language_default.code,
	maxDate,
	methods,
	name,
	onChange,
	pattern,
	required,
	rules,
	size,
	type,
	validationType,
	value,
}: InputDatePickerProps): JSX.Element => {
	const { t } = useTranslation();
	let register = null;
	const isControled = methods?.register;
	const registerRules = {
	} as InputProps['registerRules'];

	const hookRef = useRef<HTMLDivElement>(null);
	const localRef = innerRef || hookRef;

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: customError ? customError : t('general.form.input.error.default')
			};
			registerRules.pattern = {
				value: pattern || formUtils.rulesByType['default'],
				message: customError ? customError : t(`general.form.input.error.${type}`)
			};
		}

		register = methods.register(name, registerRules);
	}

	const isInvalid = invalidDate ? true : false;

	const [
		valueDate,
		setValueDate
	] = useState(value || defaultValue ? new Date(value || defaultValue) : hasPlaceholder ? null : new Date());
	const [
		open,
		setOpen
	] = useState(isOpen);

	const [
		isInvalidState,
		setInvalidState
	] = useState(isInvalid);

	const calendarIconElement = hasCalendarIcon ? (
		<Icon
			data-testid={`${dataTestid}-icon-calendar`}
			fontStyle={EnumFontStyle.LIGHT}
			name="calendar-day"
		/>
	) : null;

	const labelElement = label ? (
		<InputLabel
			data-testid={`${dataTestid}-label`}
			id={id}
			textEllipsis={true}
		>{decodeURI(t(label))}</InputLabel>
	) : null;

	const classes = [
		styles.date_picker,
	];

	function handleOnClick() {
		setOpen(!open);
	}

	const placeholderElement = hasPlaceholder && !valueDate && !disabled  ? (
		<div
			className={styles.placeholder}
			data-testid={`${dataTestid}-placeholder`}
			onClick={handleOnClick}
		>
			{t('general.form.input.type.date_picker.placeholder')}
		</div>
	) : null;

	if (className) classes.push(className);
	if (hasShadow) classes.push(styles.shadow);
	if (hasBorder) classes.push(styles.border);
	if (size) classes.push(styles[`${'size__' + size}`]);
	if ((isInvalid || isInvalidState || methods?.formState?.errors[name])) classes.push(styles.invalid);

	const errorMsgElement = (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{customError ? customError : t('general.form.input.error.date_picker')}
		</div>
	);

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const onDateChange = (newDate: Date) => {
		setValueDate(newDate);
		if (required && !newDate) {
			setInvalidState(true);
		}
		onChange({
			target: {
				value: newDate
			}
		} as unknown as ChangeEvent<HTMLInputElement>);
	};

	/* istanbul ignore next */
	const DatePickerComponent = (field?: ControllerRenderProps, fieldState?: ControllerFieldState) => (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
			ref={field ? field.ref : localRef}
		>
			<div className={styles.date_picker__container}>
				{labelElement}
				{placeholderElement}
				<DatePicker
					calendarIcon={calendarIconElement}
					data-testid={`${dataTestid}-picker`}
					disabled={disabled}
					format={format}
					id={id}
					isOpen={open}
					locale={language}
					maxDate={maxDate}
					name={name}
					value={field ? field.value : valueDate}
					onChange={(date) => {
						/* istanbul ignore next */
						if (field) {
							field.onChange(date);
						}
						/* istanbul ignore next */
						onDateChange(date as Date);
					}}
				/>
				{fieldState?.error || isInvalidState ? errorMsgElement : null}
			</div>
		</div>
	);

	/* istanbul ignore next */
	return register ? (
		<Controller
			defaultValue={valueDate}
			name={name}
			render={/* istanbul ignore next */({ field, fieldState }) => DatePickerComponent(field, fieldState)}
			rules={rules}
		/>
	) : DatePickerComponent();
};

InputDatePicker.displayName = EnumComponentType.INPUT_DATE_PICKER;

export default InputDatePicker;
