import * as React from 'react';
import {
	useState,
	RefObject,
	useEffect,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	matchRoutes,
	useLocation,
	useParams,
} from 'react-router-dom';

// ENUMS
import {
	EnumThemeLink,
} from '@enums/link.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';

// CONFIG
import {
	routesMapping,
} from '@layouts/Pages/PageOfferEntry/config/offer_entry.config';

// MODULES
import {
	getURL,
} from '@modules/utils';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// TYPES
import {
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry';

// ROUTES
import PATHS from '@routes/paths';
import {
	OFFER_ENTRY_LOCATION_CREATE_ROUTES,
} from '@routes/lpdipro/private/offer-entry';

// COMPONENTS
import Icon from '@components/icon';
import Link from '@components/link';
import Sticky from '@components/sticky';
import TabList from '@components/tab-list';
import Tab from '@components/tab-list/tab';

// STYLES
import styles from './header.module.scss';

interface HeaderProps {
	isDesktopResolution: boolean;
	contentEntryOfferRef: RefObject<HTMLDivElement>;
	offerState?: OfferStateProps;
}

const Header = ({
	isDesktopResolution,
	contentEntryOfferRef,
	offerState,
}: HeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const navIsOpen = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);

	const location = useLocation();

	const { offerid } = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : undefined;

	const titleElement = (
		<div className={styles.title}>
			<h1>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.title')
				})}
			</h1>
		</div>
	);

	const tabListElement = (
		<TabList>
			{routesMapping.map((mapping, key) => {
				return (
					<Tab
						className={styles.step}
						disabled={offerState?.is_published || mapping.step === 'location' || (offerState?.steps && offerState.steps[routesMapping[key - 1].step]) ? false : true}
						isActive={matchRoutes(mapping.route, location.pathname)?.length ? true : false}
						isSuccessful={offerState?.steps && offerState.steps[mapping.step] ? true : false}
						key={key}
					>
						<Link
							href={getURL(matchRoutes(OFFER_ENTRY_LOCATION_CREATE_ROUTES, location.pathname)?.length ? PATHS.OFFERS.NEW : PATHS.OFFERS.EDIT[`${mapping.step}`.toUpperCase()], {
								':offerid': `${currentOfferId}`,
								':mediatype': 'photos',
							})}
							theme={EnumThemeLink.TAB}
						>
							{t('format.capitalize', {
								text: t(`page.offers_create_edit.steps.${mapping.step}`)
							})}
							<Icon
								fontStyle={offerState?.steps && offerState.steps[mapping.step] ? EnumFontStyle.SOLID : EnumFontStyle.REGULAR}
								name={offerState?.steps && offerState.steps[mapping.step] ? 'check-circle' : 'circle'}
							/>
						</Link>
					</Tab>
				);
			})}
		</TabList>
	);

	const [
		isSticky,
		setIsSticky
	] = useState(false);

	const [
		stickyCssClasses,
		setStickyCssClasses
	] = useState([
		styles.filter
	]);

	useEffect(() => {
		const baseClasses = [
			styles.filter
		];
		if (isSticky) {
			baseClasses.push(styles.filter__sticky);
			if (navIsOpen && isDesktopResolution) {
				baseClasses.push(styles.filter__sticky__navisopen);
			}
		}
		setStickyCssClasses(baseClasses);
	}, [
		navIsOpen,
		isSticky
	]);

	return (
		<>
			<div className={styles.header}>
				{titleElement}
				<Sticky
					className={stickyCssClasses.join(' ')}
					isDesktopResolution={true}
					isSticky={isSticky}
					scrollContainerRef={isDesktopResolution ? contentEntryOfferRef : undefined}
					setIsSticky={setIsSticky}
				>
					{tabListElement}
				</Sticky>
			</div>
		</>
	);
};

export default withTranslation()(Header);
