import * as React from 'react';
import {
	ChangeEvent,
	FocusEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumFormMode,
	EnumInputSize,
	EnumInputType,
} from '@enums/form.enum';

// COMPONENTS
import Input, {
	InputProps,
	MessageProps,
} from '@components/form/input';
import LabelInput from '@components/form/input-label';
import Icon from '@components/icon';

// MODULES
import formUtils from '@modules/formUtils';

// STYLING
import styles from './input-text.module.scss';

type AllowedInputTypesText = EnumInputType.EMAIL | EnumInputType.HIDDEN | EnumInputType.NUMBER | EnumInputType.TEL | EnumInputType.TEXT;

export interface InputTextProps extends InputProps {
	'data-check-value'?: string;
	iconLeft?: string;
	iconRight?: string;
	iconRightToggled?: string;
	initialtype?: EnumInputType;
	onClickIconRight?: (event: MouseEvent<HTMLElement>) => void;
	size?: EnumInputSize;
	toggled?: boolean;
	typeCustom?: AllowedInputTypesText;
	value?: string;
}

const InputText = ({
	autoComplete,
	autoFocus,
	className,
	customError,
	defaultValue,
	dirty = false,
	disabled,
	hasBorder,
	hasShadow,
	iconLeft,
	iconRight,
	innerRef,
	id,
	invalid,
	label,
	maxLength,
	methods,
	name,
	onBlur,
	onChange,
	onClickIconRight,
	pattern,
	placeholder,
	required,
	size = EnumInputSize.DEFAULT,
	tabIndex = 0,
	type,
	typeCustom,
	validationType,
	...otherProps
}: InputTextProps): JSX.Element => {
	const { i18n, t } = useTranslation();
	const isControled = methods?.register;
	const registerRules = {
	} as InputProps['registerRules'];

	const typeForRules = type === 'email' ? 'email' : 'default';

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: t('general.form.input.error.default')
			};
			registerRules.pattern = {
				value: pattern || formUtils.rulesByType[typeForRules],
				message: t(`general.form.input.error.${type}`)
			};
		}
	}

	const initialState: InputTextProps = {
		...otherProps,
		dirty: dirty,
		disabled,
		id,
		name,
		initialtype: typeCustom || type,
		invalid,
		required,
		toggled: false,
		error: methods?.formState?.errors[name] as MessageProps,
	};

	const [
		state,
		setState,
	] = useState(initialState);

	const ref = useRef();

	function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
		const newCssClasses = cssClasses.filter(cssClass => cssClass !== styles.focused);
		setCssClasses([
			...newCssClasses
		]);
		if (onBlur) onBlur(event);
	}

	function getErrorMsg({
		initialtype,
		value,
		name
	}: InputTextProps) {
		let errorMsg = state?.error?.message || null;

		// change error msg from type only when field has value
		if (value?.length && otherProps['data-check-value']?.length) {
			errorMsg = t('general.form.input.error.not_equal', {
				'%type%': t('format.lowercase', {
					'text': t(`general.form.input.type.${initialtype}.label_other`)
				})
			}) as string;
		} else if (value?.length && autoComplete === 'new-password') {
			errorMsg = t('general.form.input.error.password_format') as string;
		} else if (value?.length && (i18n.exists(`general.form.input.error.${initialtype}`))) {
			errorMsg = (t(`general.form.input.error.${initialtype}`)) as string;
		}

		errorMsg = (errorMsg ? errorMsg : t('general.form.input.error.default')) as string;
		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.setError(name);
		}

		return errorMsg;
	}

	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		const inputHTML = event.currentTarget;
		const isDirty = dirty || validationType !== EnumFormMode.ON_SUBMIT && (state.dirty === false || (state?.value !== state.initialValue) as boolean);
		const isInvalid = validationType !== EnumFormMode.ON_SUBMIT && state.required ? !formUtils.isInputValid(inputHTML) : false;

		let newState: InputTextProps = {
			...state,
			dirty: isDirty,
			error: null,
			required: required,
			value: inputHTML.value,
		};

		if (isInvalid) {
			newState = {
				...newState,
				error: {
					message: getErrorMsg(newState)
				},
			};
		}

		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.clearErrors(newState.name);
		}

		setState(newState);

		if (onChange) onChange(event, newState);
	}

	let errorMsgText = null;
	if (typeof state.error === 'object' && state.error?.message) {
		errorMsgText = customError ? customError : state.error.message;
	} else if (typeof invalid === 'object' && invalid?.message) {
		errorMsgText = customError ? customError : invalid?.message;
	}

	const classes = [
		styles.input_text,
		`input__${id}`,
	];

	if (className) classes.push(className);
	if (iconLeft) classes.push(styles.has_icon_left);
	if (iconRight) classes.push(styles.has_icon_right);
	classes.push(styles[`${'size__' + size}`]);
	if (errorMsgText) classes.push(styles.invalid);
	if (disabled) classes.push(styles.disabled);

	const [
		cssClasses,
		setCssClasses
	] = useState(classes);

	const handleOnFocus = () => {
		setCssClasses([
			...cssClasses,
			styles.focused
		]);
	};

	const iconLeftElement = (
		<Icon
			className={`${styles.icon} ${styles.icon__left}`}
			fontStyle={EnumFontStyle.LIGHT}
			name={iconLeft}
		/>
	);

	const handleClickIcon = (event: MouseEvent<HTMLElement>) => {
		onClickIconRight(event);
	};

	const iconRightElement = iconRight ? (
		<Icon
			className={`${styles.icon} ${styles.icon__right}`}
			data-testid={`${otherProps['data-testid']}-icon-right`}
			fontStyle={EnumFontStyle.LIGHT}
			name={iconRight}
			onClick={onClickIconRight ? handleClickIcon : null}
		/>
	) : null;

	const errorMsgElement = errorMsgText ? (
		<div
			className={styles.error}
			data-error
			data-testid={`${otherProps['data-testid']}-error`}
			role="alert"
		>
			{errorMsgText}
		</div>
	) : null;

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={otherProps['data-testid']}
			ref={ref}
		>
			{label ? (
				<LabelInput
					data-testid={`${otherProps['data-testid']}-label`}
					id={id}
					textEllipsis={true}
				>
					{decodeURI(t(label))}
				</LabelInput>
			) : null}

			{iconLeft ? iconLeftElement : null}
			<Input
				autoComplete={autoComplete}
				autoFocus={autoFocus}
				data-check-value={otherProps['data-check-value']}
				data-testid={`${otherProps['data-testid']}-input`}
				defaultValue={defaultValue}
				disabled={disabled}
				hasBorder={hasBorder}
				hasShadow={hasShadow}
				id={id}
				innerRef={innerRef}
				invalid={invalid || state.error}
				maxLength={maxLength}
				methods={methods}
				name={name}
				pattern={pattern || null}
				placeholder={placeholder ? t(placeholder) : ''}
				registerRules={registerRules}
				tabIndex={tabIndex}
				type={type}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
				onFocus={handleOnFocus}
			/>
			{iconRight ? iconRightElement : null}
			{state.error?.message || invalid ? errorMsgElement : undefined}
		</div>
	);
};

InputText.displayName = EnumComponentType.INPUT_TEXT;

export default InputText;
