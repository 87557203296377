import React, {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	SetStateAction,
	useRef,
	useState,
} from 'react';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// EXCEPTIONS
import PutOfferContactsError from '@exceptions/PutOfferContactsError';

// HOOKS
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// MODULES
import i18n from '@modules/i18n';
import {
	handleErrorResponse,
} from '@modules/utils';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	OfferStateContactsProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	putOfferContacts,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	InputLinkState,
} from '@layouts/Pages/PageOfferEntry/contacts/contact-row';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputText from '@components/form/input-text';
import Icon from '@components/icon';
import Link from '@components/link';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import Modal from '@components/modal';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import styles from '../contact-row.module.scss';

export interface LinkElementProps {
	activeElement: string | null;
	currentOfferId: number;
	isDesktopResolution: boolean;
	onClickRow: (id: number) => void;
	offerContact: ContactOfferJson;
	offerStateContacts: OfferStateContactsProps;
	rowId: number;
	selectedRowId: number;
	setActiveElement: Dispatch<SetStateAction<string | null>>;
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
}

function LinkElement({
	activeElement,
	currentOfferId,
	isDesktopResolution,
	onClickRow,
	offerContact,
	offerStateContacts,
	rowId,
	selectedRowId,
	setActiveElement,
	setOfferStateContacts,
}: LinkElementProps) {
	const [
		inputValueLink,
		setInputValueLink
	] = useState<InputLinkState>({
		value: undefined,
		type: undefined
	});

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const handleChangeLink = async (event: ChangeEvent<HTMLInputElement>, type: 'web_title' | 'web_reference') => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		setInputValueLink({
			value,
			type
		});
	};

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		if (isDesktopResolution) {
			if (onClickRow && offerContact?.id) {
				onClickRow(offerContact.id);
			}
			const id = event.currentTarget.id;
			setActiveElement(prev => (prev === id ? null : id));
		} else if (offerContact?.id) {
			setIsOpenModal(true);
		}
		const findAndFocusInput = () => {
			if (inputRef.current instanceof HTMLInputElement) {
				inputRef.current.focus();
				return true;
			}
			return false;
		};

		let attempts = 0;
		const maxAttempts = 5;
		const tryFocus = () => {
			if (attempts >= maxAttempts) return;
			setTimeout(() => {
				if (!findAndFocusInput()) {
					attempts++;
					tryFocus();
				}
			}, 100 * (attempts + 1));
		};
		tryFocus();
	};

	useDebouncedEffect(() => {
		const updateLink = async () => {

			if (offerContact?.id) {
				const putOfferContactsResponse = await putOfferContacts(currentOfferId, offerContact.id, {
					comment: offerContact.comment,
					is_mandated: offerContact.is_mandated,
					mandate_number: offerContact.mandate_number,
					original_mail_date: offerContact.original_mail_date,
					reference: offerContact?.reference,
					web_reference: inputValueLink.type === 'web_reference' ? inputValueLink.value : offerContact.web_reference,
					web_title: inputValueLink.type === 'web_title' ? inputValueLink.value : offerContact.web_title,
					contact_id: offerContact.contact_id,
				});
				handleErrorResponse(putOfferContactsResponse, PutOfferContactsError);
				if (putOfferContactsResponse.status === 200) {
					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: offerStateContacts?.contacts?.collection.map(contact =>
								contact.id === offerContact.id
									? {
										...contact,
										...(inputValueLink.type === 'web_title'
											? {
												web_title: inputValueLink.value
											}
											: {
												web_reference: inputValueLink.value
											})
									}
									: contact
							),
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}
			}
		};

		if (inputValueLink !== undefined) {
			updateLink();
		}
	}, [
		inputValueLink
	], APP_CONF_VARS.timeout.debounce);

	const inputRef = useRef<HTMLInputElement>(null);
	const formRef = useRef(null);

	const inputLinkElement = (
		<>
			<InputText
				autoComplete='web_title'
				autoFocus={true}
				className={styles.input_link}
				defaultValue={offerContact?.web_title}
				innerRef={inputRef}
				label={i18n.t('format.capitalize', {
					text: i18n.t('page.offers_create_edit.contacts.link.web_title.label')
				})}
				name={`link_web_title_${rowId}`}
				placeholder={i18n.t('format.capitalize', {
					text: i18n.t('page.offers_create_edit.contacts.link.web_title.input_placeholder')
				})}
				tabIndex={0}
				value={offerContact?.web_title}
				onChange={(event) => handleChangeLink(event, 'web_title')}
			/>
			<InputText
				defaultValue={offerContact?.web_reference}
				label={i18n.t('format.capitalize', {
					text: i18n.t('page.offers_create_edit.contacts.link.web_reference.label')
				})}
				name={`link_web_reference_${rowId}`}
				placeholder={i18n.t('format.capitalize', {
					text: i18n.t('page.offers_create_edit.contacts.link.web_reference.input_placeholder')
				})}
				value={offerContact?.web_reference}
				onChange={(event) => handleChangeLink(event, 'web_reference')}
			/>
		</>
	);

	const displayLink = (
		<div className={styles.display_link}>
			<Icon
				className={styles.icon}
				name={'edit'}
			/>
			<Link
				className={styles.link}
				href={offerContact?.web_reference}
				target={EnumTargetLink.BLANK}
			>
				{offerContact?.web_title || offerContact?.web_reference}
			</Link>
		</div>
	);

	const modalLinkMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={i18n.t('format.capitalize', {
				text: i18n.t('page.offers_create_edit.contacts.header_table_link')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
				innerRef={formRef}
				method={EnumQueryMethods.GET}
			>
				{inputLinkElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={i18n.t('format.capitalize', {
						text: i18n.t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const linkRow = (
		<>
			<div
				className={`
			${styles.cell_link}
			${activeElement === 'link' && selectedRowId === offerContact?.id ? styles.active : ''}
			${!offerContact?.id ? styles.not_allowed : ''}
		`}
				id={`link_${rowId}`}
				onClick={handleOnClick}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_link}
						clickable={true}
						disabled={!offerContact?.id}
						htmlElement={inputLinkElement}
						id={`tooltip_link_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.BOTTOM_START}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_link_content}>
							{offerContact?.web_reference ? displayLink : (
								<span className={styles.placeholder}>
									{i18n.t('format.capitalize', {
										text: i18n.t('page.offers_create_edit.contacts.link.placeholder')
									})}
								</span>
							)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {i18n.t('format.capitalize', {
							text: i18n.t('page.offers_create_edit.contacts.header_table_link')
						})}</span>
						<span className={styles.mobile_value}>{offerContact?.web_reference ? offerContact?.web_reference : undefined}</span>
					</>
				)}
			</div>
			{modalLinkMobile}
		</>
	);
	return linkRow;
}

export default LinkElement;
