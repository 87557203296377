// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UmHaX0im{display:flex;flex-direction:column;margin-bottom:2.14285714rem}.JuQ6l2MF{align-items:center;background-color:var(--color-background-default);bottom:0;box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2),-0.07142857rem -0.07142857rem 0.35714286rem 0 hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.2);display:flex;flex-direction:row;justify-content:space-between;left:0;padding:1rem 1.14285714rem;position:fixed;right:0;width:100%;z-index:1}@media screen and (min-width: 768Px)and (orientation: landscape){.JuQ6l2MF{background-color:rgba(0,0,0,0);bottom:inherit;box-shadow:none;display:flex;flex:0;flex-direction:row;justify-content:start;left:inherit;padding:0;position:relative;right:inherit;width:auto}}.JuQ6l2MF .RF7q9MNq{flex:1;margin-bottom:0}.JuQ6l2MF .RF7q9MNq:first-child{margin-right:0.28571429rem}@media screen and (min-width: 768Px)and (orientation: landscape){.JuQ6l2MF .RF7q9MNq:first-child{flex:inherit;margin-right:0.71428571rem}}.JuQ6l2MF .RF7q9MNq:last-child{margin-left:0.28571429rem}@media screen and (min-width: 768Px)and (orientation: landscape){.JuQ6l2MF .RF7q9MNq:last-child{flex:inherit;margin-left:0.71428571rem}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `UmHaX0im`,
	"button_group": `JuQ6l2MF`,
	"button": `RF7q9MNq`
};
export default ___CSS_LOADER_EXPORT___;
