import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	matchRoutes,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';

// EXCEPTIONS
import GetOfferEnumsError from '@exceptions/GetOfferEnumsError';

// REDUCERS
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';
import {
	Media,
} from '@@types/Media';

// ROUTES
import PATHS from '@routes/paths';

// LAYOUTS
import {
	getOffer,
	getOfferContacts,
	getOfferEnums,
	getOfferMedia,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	routesMapping,
} from '@layouts/Pages/PageOfferEntry/config/offer_entry.config';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// LAYOUTS
import Page from '@layouts/Pages/Page';
import Header from '@layouts/Pages/PageOfferEntry/header';

// STYLES
import styles from './PageOfferEntry.module.scss';

export interface AddressesProps {
	id: number;
	decoded: Record<string, unknown>;
	icon?: string;
	is_main: boolean;
	is_valid?: boolean;
	street_raw: string;
	pretty_street_raw?: string;
	type?: string;
}

export interface OfferStateProps {
	addresses?: AddressesProps[];
	allowed_activities?: string;
	annual_indexation?: string;
	building_description?: string;
	building_name?: string;
	building_services?: string;
	caz_name?: string;
	certification_comment?: string;
	commercialized_at?: string;
	construction_comment?: string;
	created_at?: string;
	deed_fees?: string;
	description_real_estate_website?: string;
	dpe_energy_consumption?: number;
	dpe_greenhouse_gas_emission?: number;
	entrance_fee?: number;
	fees_comments?: string;
	fiscal_regime?: string;
	grade_level_door?: number;
	has_branch_line_ite?: boolean;
	has_description_real_estate_website?: boolean;
	fees_comment?: string;
	show_office_tax?: boolean;
	show_professional_tax?: boolean;
	show_property_tax?: boolean;
	show_teom?: boolean;
	id?: number;
	infos?: Record<string, unknown>[] | Record<string, undefined>;
	is_dpe_applicable?: boolean;
	is_independent_building?: boolean;
	is_private?: boolean;
	is_published?: boolean;
	is_rental_fees_charged_to_the_lessee?: boolean;
	is_sale_fees_charged_to_the_buyer?: boolean;
	is_single_storey_building?: boolean;
	leases?: {
			details?: {
					[key: string]: string | number;
			};
			rental_ref_lease_type_id?: number;
	}[];
	loading_dock?: number;
	logistic_hub_height?: number;
	lots_resume?: {
			[key: string]: number;
	};
	main_photo?: Media;
	management_fees_value?: string;
	management_fees_unit?: string;
	mandate?: {
			type?: string;
			is_owner?: boolean;
	};
	meter_linear_showcase?: number;
	nature?: string;
	office_tax?: string;
	observations?: string;
	private_offer_title?: string;
	profession_tax?: string;
	profession_tax_rate?: string;
	program_name?: string;
	prohibited_activities?: string;
	property_tax?: string;
	quote_part_rie?: string;
	ref_announcement_indexation_id?: string;
	ref_announcement_payment_type_id?: string;
	ref_building_commercial_zone_id?: string;
	ref_building_warehouse_classification_id?: string;
	ref_building_warehouse_typology_id?: string;
	ref_dpe_energy_consumption_id?: string;
	ref_dpe_greenhouse_gas_emission_id?: string;
	ref_icpe_code_ids?: string[];
	ref_offer_certification_classement_ids?: string[];
	ref_offer_certification_label_ids?: string[];
	ref_property_state_id?: number;
	ref_fiscal_regime_id?: string;
	ref_lease_activity_ids?: string;
	ref_lease_activity?: string[];
	ref_property_nature_main_id?: number;
	ref_property_nature_main?: string;
	ref_rental_fees_unit_id?: string;
	rental_caution?: string;
	rental_cession_price?: number;
	rental_fees?: number;
	rental_ref_price_unit?: string;
	sale_fees?: number;
	sale_ref_sale_fees_unit_id?: string;
	security_deposit?: string;
	security_deposit_ref_price_unit_id?: string;
	showcase?: number;
	state?: string;
	state_date?: string;
	steps?: {
			[key: string]: boolean;
	};
	suspended_at?: string;
	tax_comment?: string;
	teom?: string;
	total_area_main_natures?: number;
	transport_access?: string;
	types?: string[];
	under_option_at?: string;
	under_promise_at?: string;
	updated_at?: string;
	updated_steps?: {
			[key: string]: boolean;
	};
}

export interface ObjectEnum {
	key: string;
	value: number | string;
}
export interface OfferEnumsStateProps {
	contact_roles?: ObjectEnum[];
	has_branch_line_ite?: boolean[];
	has_description_real_estate_website?: boolean[];
	is_dpe_applicable?: boolean[];
	is_independent_building?: boolean[];
	is_single_storey_building?: boolean[];
	mandate_type?: string[];
	ref_announcement_indexations?: ObjectEnum[];
	ref_announcement_payment_types?: ObjectEnum[];
	ref_building_commercial_zones?: string[];
	ref_building_warehouse_classifications?: string[];
	ref_building_warehouse_typologies?: string[];
	ref_dpe_energy_consumption_ids?: string[];
	ref_dpe_greenhouse_gas_emission_ids?: string[];
	ref_fiscal_regimes?: ObjectEnum[];
	ref_icpe_codes?: ObjectEnum[];
	ref_lease_activities?: string[];
	ref_sale_fees_units?: ObjectEnum[];
	ref_offer_certification_classements?: string[];
	ref_offer_certification_labels?: string[];
	ref_price_units?: ObjectEnum[];
	ref_property_nature_announcements: ObjectEnum[];
	ref_property_states?: ObjectEnum[];
	rental_ref_lease_types?: ObjectEnum[];
	ref_rental_fees_units?: ObjectEnum[];
	types?: string[];
	visibilities: ObjectEnum[];
}

export interface OfferStateContactsProps {
	contacts: Collection<ContactOfferJson, ContactOffer>;
	is_loaded: boolean;
}

export interface MediaOfferProps {
	is_loaded: boolean;
	media?: Media[];
}

export interface PageOfferContextProps {
	isDesktopResolution: boolean;
	mediaOfferState: MediaOfferProps;
	offerState?: OfferStateProps;
	offerStateContacts?: OfferStateContactsProps;
	offerEnumsState: OfferEnumsStateProps;
	setMediaOfferState: (Dispatch<SetStateAction<MediaOfferProps>>);
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
	setOfferState: (Dispatch<SetStateAction<OfferStateProps>>);
}

const PageOfferEntry = () => {
	const {offerid} = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : undefined;
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const navigate = useNavigate();

	const contentEntryOfferRef = useRef(null);

	const location = useLocation();

	const [
		isLoaded,
		setIsLoaded,
	] = useState(false);

	const [
		offerState,
		setOfferState
	] = useState();

	const [
		offerStateContacts,
		setOfferStateContacts,
	] = useState({
		is_loaded: false,
		contacts: undefined,
	});

	const [
		mediaOfferState,
		setMediaOfferState
	] = useState({
		is_loaded: false,
		media: undefined
	});

	const [
		offerEnumsState,
		setOfferEnumsState,
	] = useState(undefined);

	useEffect(() => {
		// Async function to handle data fetching
		const getOfferData = async () => {
			try {
				const enumsResponse = await getOfferEnums();
				handleErrorResponse(enumsResponse, GetOfferEnumsError);
				if (enumsResponse.status === 200) {
					setOfferEnumsState(enumsResponse.payload);
				}

				// Check if it's a new offer edit route
				if (routesMapping.some(({ route }) => matchRoutes(route, location.pathname)?.length)) {
					// Fetch enums for editing an existing offer
					const enumsResponse = await getOfferEnums();
					handleErrorResponse(enumsResponse, GetOfferEnumsError);
					if (enumsResponse.status === 200) {
						setOfferEnumsState(enumsResponse.payload);
					}

					// Fetch offer details
					const offerResponse = await getOffer(currentOfferId);
					if (offerResponse.status === 200) {
						setOfferState({
							...offerResponse.payload,
							id: currentOfferId,
						});
						setIsLoaded(true);

						// Fetch offer contacts
						const contactsResponse = await getOfferContacts(currentOfferId);
						if (contactsResponse.status === 200) {
							setOfferStateContacts({
								contacts: contactsResponse.payload,
								is_loaded: true,
							});
						}
					} else {
					// Navigate to the homepage if the offer is not found
						navigate(PATHS.HOMEPAGE);
						return;
					}

					// Fetch offer media
					const mediaResponse = await getOfferMedia(currentOfferId);
					if (mediaResponse.status === 200) {
						setMediaOfferState({
							...mediaOfferState,
							is_loaded: true,
							media: mediaResponse.payload,
						});
					}
				}
			} catch (error) {
				// Handle any error by navigating to the homepage
				navigate(PATHS.HOMEPAGE);
			} finally {
				setIsLoaded(true); // Mark as loaded when enums are fetched
				// Ensure contacts are marked as loaded even in case of error
				setOfferStateContacts((prevContacts) => ({
					...prevContacts,
					is_loaded: true,
				}));
			}
		};

		// Call the async function when the component mounts
		getOfferData();
	}, [
		currentOfferId,
	]);

	return isLoaded ? (
		<Page className={styles.PageOfferEntry}>
			<div
				className={styles.PageOfferEntry__content}
				ref={contentEntryOfferRef}
			>
				<Header
					contentEntryOfferRef={contentEntryOfferRef}
					isDesktopResolution={isDesktopResolution}
					offerState={offerState}
				/>
				<Outlet
					context={{
						isDesktopResolution,
						mediaOfferState,
						offerEnumsState,
						offerState,
						offerStateContacts,
						setMediaOfferState,
						setOfferState,
						setOfferStateContacts,
					}}
				/>
			</div>
		</Page>
	) : undefined;
};

export default withTranslation()(PageOfferEntry);
