import * as React from 'react';
import {
	MouseEvent,
	useState,
} from 'react';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// COMPONENTS
import InputText, {
	InputTextProps,
} from '@components/form/input-text';

// STYLING
import styles from './input-password.module.scss';

interface InputPasswordProps extends InputTextProps {
	type?: EnumInputType.PASSWORD | EnumInputType.TEXT;
}

const InputPassword = ({
	className,
	onClickIconRight,
	type = EnumInputType.PASSWORD,
	...otherProps
}: InputPasswordProps): JSX.Element => {
	const initialState: InputPasswordProps = {
		toggled: type === EnumInputType.PASSWORD ? false : true,
		type,
	};

	const [
		state,
		setState,
	] = useState(initialState);

	const handleClickIcon = (event: MouseEvent<HTMLElement>, isToggled: boolean) => {
		const newState = {
			...state,
			type: isToggled ? EnumInputType.TEXT : EnumInputType.PASSWORD,
			toggled: isToggled,
		};

		setState(newState as InputPasswordProps);
		if (onClickIconRight) onClickIconRight(event);
	};

	const classes = [
		styles.input_password,
	];

	if (className) classes.push(className);

	return (
		<InputText
			{...otherProps}
			className={classes.join(' ')}
			iconRight={state.toggled ? 'eye-slash' : 'eye'}
			type={state.type}
			onClickIconRight={(event) => {
				handleClickIcon(event, !state.toggled);
			}}
		/>
	);
};

InputPassword.displayName = EnumComponentType.INPUT_PASSWORD;

export {InputPassword as default,};
