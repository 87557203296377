import {
	OptionType,
} from '@components/form/input-multi-select';
import i18n from '@modules/i18n';
import {
	ChangeEvent,
	Dispatch, SetStateAction,
} from 'react';
import {
	ObjectEnum,
} from '..';

interface OfferState {
	updated_steps?: { [key: string]: boolean };
	steps?: { [key: string]: boolean };
}

// Update steps on offer state
const updatedSteps = (prevState: OfferState, step: string) => prevState.updated_steps && Object.keys(prevState.updated_steps).length
	? {
		...prevState.updated_steps,
		[step]: true
	}	: {
		[step]: true
	};

// Create option for input multi select
const createOption = (keyOrString: string, value: number | string, trad: string) => {
	const key = value !== undefined ? keyOrString : keyOrString;
	const optionValue = value !== undefined ? value : keyOrString;

	return {
		label: trad ? i18n.t('format.capitalize', {
			text: i18n.t(`references.${trad}.${key}`)
		}) : key,
		value: optionValue.toString(),
		inputValue: {
			label: trad ? i18n.t('format.capitalize', {
				text: i18n.t(`references.${trad}.${key}`)
			}) : key,
		},
	};
};
export const generateOptions = (enumArray: ObjectEnum[] | string[], trad: string = 'announcement') => {
	return enumArray?.map((item) =>
		typeof item === 'string'
			? createOption(item, item, trad)
			: createOption(item.key, item.value, trad)
	);
};

// Find default option for input multi select
export const findDefaultOption = (
	options: OptionType[] = [
	],
	stateValue: string | number | (string | number)[] | undefined
) => {
	if (Array.isArray(stateValue)) {
		return options.filter(option => stateValue.includes(option.value as string | number));
	} else {
		return options.find(option => option.value === (stateValue as string | number)?.toString());
	}
};

// Handle option change for input multi select
export const handleOptionChange = (
	name: string,
	offerState: OfferState,
	selected: OptionType | OptionType[],
	step: string,
	setOfferState: Dispatch<SetStateAction<OfferState>>
) => {
	const newSteps = updatedSteps(offerState, step);

	setOfferState((prevState) => ({
		...prevState,
		[name]: Array.isArray(selected)
			? selected.map((item) => item.value)
			: selected?.value,
		steps: {
			...offerState?.steps,
			[step]: false,
		},
		updated_steps: newSteps,
	}));
};

// Handle option change for input multi select
export const handleOnChangeToggle = (
	name: string,
	offerState: OfferState,
	selectedIds: string[],
	step: string,
	setOfferState: Dispatch<SetStateAction<OfferState>>
) => {
	const newSteps = updatedSteps(offerState, step);

	setOfferState((prevState) => ({
		...prevState,
		[name]: selectedIds[0] === 'true' ? true : selectedIds[0] === 'false' ? false : selectedIds[0],
		steps: {
			...offerState?.steps,
			[step]: false,
		},
		updated_steps: newSteps,
	}));
};

// Handle input change
export const handleOnChange = (
	event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
	step: string,
	setOfferState: Dispatch<SetStateAction<OfferState>>
) => {
	const target = event.target as HTMLInputElement;
	let name: string;

	let value: string | boolean;
	if (target.type === 'checkbox') {
		value = target.checked;
		name = target.name;
	} else if (target.type === 'radio') {
		value = target.value === 'true' ? true : target.value === 'false' ? false : target.value;
		name = target.name.replace(/\[.*?\]$/, '');
	} else {
		value = target.value;
		name = target.name;
	}

	setOfferState(prevState => {
		const newSteps = updatedSteps(prevState, step);

		return {
			...prevState,
			[name]: value as string | boolean,
			steps: {
				...prevState.steps,
				[step]: false,
			},
			updated_steps: newSteps,
		};
	});
};
