import React from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// LAYOUTS
import {
	PageOfferContextProps, 
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// STYLES
import styles from './lots.module.scss';

const Lots = () => {
	const { t } = useTranslation();

	const {
		offerState,
		setOfferState,
		isDesktopResolution,
	}: PageOfferContextProps = useOutletContext();

	return (
		<div className={`${styles.section} ${styles.section__full}`}>
			<div className={styles.step_progress}>
				{t('format.capitalize', {
					text: `${t('page.offers_create_edit.step')} 4/8`
				})}
			</div>

			<ValidationForm
				isDesktopResolution={isDesktopResolution}
				isValid={true}
				offerState={offerState}
				setOfferState={setOfferState}
			/>
		</div>
	);
};

export default withTranslation()(Lots);
