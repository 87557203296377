import * as React from 'react';

import {
	ChangeEvent,
	MouseEvent,
	useState,
} from 'react';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// COMPONENTS
import InputText, {
	InputTextProps,
} from '@components/form/input-text';

// STYLING
import styles from './input-search.module.scss';

interface InputSearchProps extends InputTextProps {
	iconIsToggled?: boolean;
}

const InputSearch = ({
	'data-testid': dataTestid,
	className,
	defaultValue,
	disabled = false,
	hasBorder = true,
	hasShadow = false,
	onChange,
	size,
	...otherProps
}: InputSearchProps): JSX.Element => {
	const classes = [
		styles.search
	];
	if (className) classes.push(className);

	const initialState: InputSearchProps = {
		...otherProps,
		disabled,
		value: defaultValue as string,
		iconIsToggled: true,
	};
	initialState.iconIsToggled = defaultValue?.length ? true : false;

	const [
		state,
		setState,
	] = useState(initialState);

	// Manage click on icon to clear input value
	const handleOnClickClearSearch = (event: MouseEvent<HTMLInputElement>) => {
		const icon = event.currentTarget;
		const input = icon.closest('div').querySelector('input');
		input.value = null;
		const newState: InputSearchProps = {
			...state,
			iconRight: null,
			value: null,
		};
		setState(newState);
		if (onChange) onChange({
			target:input
		} as ChangeEvent<HTMLInputElement>, newState);
	};

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>, actualState: InputSearchProps) => {
		const target = event.currentTarget;
		const newState: InputSearchProps = {
			...state,
			...actualState,
			iconRight: target.value.length ? 'times' : null,
		};
		setState(newState);
		if (onChange) onChange(event, newState);
	};

	return (
		<InputText
			{...otherProps}
			className={classes.join(' ')}
			data-testid={`${dataTestid}-search`}
			defaultValue={defaultValue as string}
			disabled={disabled}
			hasBorder={hasBorder}
			hasShadow={hasShadow}
			iconLeft='search'
			iconRight={state.value?.length ? 'times' : null}
			size={size}
			type={EnumInputType.TEXT}
			onChange={handleOnChange}
			onClickIconRight={handleOnClickClearSearch}
		/>
	);
};

InputSearch.displayName = EnumComponentType.INPUT_SEARCH;

export {
	InputSearch as default,
	InputSearchProps,
};
