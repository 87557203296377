import React, {
	useEffect,
	useRef,
	ReactNode,
	RefObject,
	Dispatch,
	SetStateAction,
} from 'react';

// STYLES
import styles from './sticky.module.scss';

export interface StickyProps {
    children: ReactNode;
	scrollContainerRef: RefObject<HTMLElement>;
    className?: string;
	isSticky: boolean;
	setIsSticky: Dispatch<SetStateAction<boolean>>;
	isDesktopResolution?: boolean;
}

const Sticky = ({
	children,
	className,
	isSticky,
	setIsSticky,
	scrollContainerRef,
	isDesktopResolution = false,
	...otherProps
}: StickyProps) => {
	const cssClasses = [
		styles.sticky,
	];

	if (className) cssClasses.push(className);

	const stickyRef = useRef<HTMLDivElement>(null);
	let initialSticky = false;

	const initialStickyTop = useRef<number | null>(null);

	const handleScroll = () => {
		const isWindowScrolling = scrollContainerRef?.current === undefined;
		const scrollTop = isWindowScrolling
			? window.scrollY
			: scrollContainerRef.current.scrollTop;

		const stickyTop = stickyRef?.current?.getBoundingClientRect().top;

		if (initialStickyTop.current === null) {
			initialStickyTop.current = stickyRef.current.getBoundingClientRect().top;
		}

		const isStickyNow = isWindowScrolling
			? window.scrollY >= initialStickyTop.current
			: scrollTop > stickyTop;
		if (isStickyNow !== initialSticky) {
			initialSticky = isStickyNow;
			setIsSticky(isStickyNow);
		}
	};

	useEffect(() => {
		const isWindowScrolling = scrollContainerRef?.current === undefined;
		const scrollContainer =  isWindowScrolling ? window : scrollContainerRef?.current;
		scrollContainer.addEventListener('scroll', handleScroll);

		return () => {
			scrollContainer.removeEventListener('scroll', handleScroll);
		};
	}, [
		scrollContainerRef?.current,
		isDesktopResolution,
	]);

	if (isSticky) {
		cssClasses.push(styles.sticky__fixed);
	} else {
		cssClasses.filter(cssClass => cssClass !== styles.sticky__fixed);
	}

	return (
		<div
			className={cssClasses.join(' ')}
			ref={stickyRef}
			{...otherProps}
		>
			{children}
		</div>
	);
};

export default Sticky;
