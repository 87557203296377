import * as React from 'react';
import {
	MouseEvent,
	useEffect,
	useRef,
} from 'react';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import useMouseEnterLeaveEffect from '@hooks/useMouseEnterLeaveEffect/hook.useMouseEnterLeaveEffect';

// COMPONENT
import {
	TooltipProps,
} from '@components/tooltip';
import {
	Tooltip as ReactTooltip,
} from 'react-tooltip';

// STYLES
import 'react-tooltip/dist/react-tooltip.css';
import styles from './tooltip-modal.module.scss';

export interface TooltipModalClickProps extends TooltipProps {
	onCloseHover?: boolean;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const TooltipModal = ({
	children,
	className,
	clickable = true,
	'data-testid': dataTestid,
	delayHide = 200,
	delayShow = 500,
	disabled = false,
	htmlElement,
	id,
	isOpen,
	offset = 10,
	onCloseHover,
	onClick,
	openOnClick = true,
	place,
	setIsOpen = () => {},
}: TooltipModalClickProps): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);
	const cssClasses = [
	];

	if (className) cssClasses.push(className);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setIsOpen(!isOpen);
		document.body.click();
	};

	const handleHideDropdown = () => {
		setIsOpen(false);
	};

	const [
		isActive
	] = useMouseEnterLeaveEffect(ref, false);

	useEffect(() => {
		const timer = !isActive && setTimeout(handleHideDropdown, APP_CONF_VARS.timeout.mouseleave);
		if (isActive && !openOnClick && onCloseHover) setIsOpen(true);
		return () => {
			clearTimeout(timer);
		};
	}, [
		isActive,
		openOnClick
	]);

	return (

		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			ref={ref}
			onClick={onClick}
		>
			<a
				data-testid={`${dataTestid}-trigger`}
				data-tooltip-id={id}
				onClick={onCloseHover ? handleOnClick : undefined}
			>
				{children}
			</a>
			{disabled ? null : (
				<ReactTooltip
					afterHide={onCloseHover ? handleHideDropdown : undefined}
					className={styles.customTooltip}
					clickable={clickable}
					closeEvents={!onCloseHover ? {
						mouseleave: openOnClick ? false : true,
						blur: false,
						click: openOnClick ? true : false,
						dblclick: false,
						mouseup: false,
					} : undefined}
					data-testid={`${dataTestid}-tooltip`}
					delayHide={delayHide}
					delayShow={delayShow}
					float={false}
					globalCloseEvents={{
						escape: true,
						clickOutsideAnchor: openOnClick ? true : false,
					}}
					id={id}
					isOpen={onCloseHover ? isOpen : undefined}
					noArrow={true}
					offset={offset}
					opacity={1}
					openEvents={!onCloseHover ? {
						mouseenter: openOnClick ? false : true,
						focus: false,
						click: openOnClick ? true : false,
						dblclick: false,
						mousedown: false,
					} : undefined}
					openOnClick={openOnClick}
					place={place}
					variant='light'
				>
					{htmlElement}
				</ReactTooltip>
			)}
		</div>
	);
};

export default TooltipModal;

