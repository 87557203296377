import React, {
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// CONF
import GetLotsOfferError from '@exceptions/GetLotsOfferError';
import {
	getLotsOffer,
} from '../../config/fetch';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	Lot, LotJson,
} from '@@types/Lot';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';
import {
	EnumMandateType,
} from '@enums/mandate.enum';
import {
	EnumStatusTheme,
	EnumTheme,
	EnumThemeLabel,
} from '@enums/theme.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// MODULES
import {
	getURL,
	handleErrorResponse,
} from '@modules/utils';

// CONFIG
import getColumns from '@layouts/Pages/PageOfferEntry/location/config/column-location-lots-table.config';
import PATHS from '@routes/paths';

// LAYOUTS
import {
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry/index';

// COMPONENTS
import Button from '@components/button';
import Icon from '@components/icon';
import Image from '@components/image';
import LabelStatus from '@components/label-status';
import Link from '@components/link';
import Paragraph from '@components/paragraph';
import Table from '@components/table';
import Text from '@components/text';
import Tooltip from '@components/tooltip';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from './offer-row.module.scss';

interface OfferRowProps {
    className?: string;
	isDesktopResolution?: boolean;
	offer: OfferStateProps;
}

const OfferRow = ({
	className,
	isDesktopResolution,
	offer,
}: OfferRowProps): JSX.Element => {
	const { t } = useTranslation();

	const currentLanguage = useSelector((state: ReducerInstance) => state.app?.instance?.localization?.actualLanguage);

	const [
		isOpenTooltipModal,
		setIsOpenTooltipModal
	] = useState(false);

	const [
		isLoadingLots,
		setIsLoadingLots
	] = useState(false);

	const [
		lots,
		setLots,
	] = useState(undefined as Collection<LotJson, Lot>);

	const cssClasses = [
		styles.row,
	];

	if (className) cssClasses.push(className);

	const mainPhotoElement = (
		<Image
			className={styles.image}
			src={offer?.main_photo?.url}
		/>
	);

	const mainPhotoCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__image}`}
		>
			{mainPhotoElement}
		</div>
	);

	const natureElement = offer?.ref_property_nature_main ? (
		<Text
			ellipsis={true}
		>
			{t('format.capitalize', {
				text: t(`references.natures.${offer.ref_property_nature_main}`)
			})}
		</Text>
	) : undefined;

	const natureCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__nature}`}
		>
			{natureElement}
		</div>
	);

	const refElement = (
		<Link
			href={getURL(PATHS.OFFERS.SHEET.LEGACY, {
				':offerid': `${offer.id}`,
			})}
			target={EnumTargetLink.BLANK}
		>
			{t('format.capitalize', {
				text: t('general.ref', {
					'%ref%': offer.id
				})
			})}
		</Link>
	);

	const refCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__reference}`}
		>
			{refElement}
		</div>
	);

	const statusElement = offer?.state ? (
		<LabelStatus
			context='female'
			text={offer.state}
		/>
	) : undefined;

	const statusCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__status}`}
		>
			{statusElement}
		</div>
	);

	const statusDateElement = offer?.state_date ? new Date(offer.state_date).toLocaleDateString(currentLanguage) : undefined;

	const statusDateCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__date}`}
		>
			{statusDateElement}
		</div>
	);

	const privateElement = (
		<Icon
			className={styles.private_icon}
			fontStyle={EnumFontStyle.SOLID}
			name='lock'
		/>
	);

	const privateCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__private}`}
		>
			{offer.is_private ? (
				<Tooltip
					content={t('format.capitalize', {
						text: t('general.private', {
							context: 'female'
						})
					})}
					id={`icon_lock_${offer.id}`}
				>
					{privateElement}
				</Tooltip>
			) : undefined}
		</div>
	);

	let mandateTheme = undefined;
	switch (true) {
		case offer.mandate?.type === EnumMandateType.EXCLUSIVE && offer.mandate?.is_owner:
			mandateTheme = 'success';
			break;
		case offer.mandate?.type === EnumMandateType.EXCLUSIVE:
			mandateTheme = 'elevation';
			break;
		case offer.mandate?.type === EnumMandateType.SIMPLE:
			mandateTheme = 'warning';
			break;
		case offer.mandate?.type === EnumMandateType.REGULATION:
			mandateTheme = 'error';
			break;
	}

	const mandateTypeElement = mandateTheme ? (
		<LabelStatus
			hasBackground={false}
			text={t('format.capitalize', {
				text: t(`general.mandate.${offer.mandate.type}`)
			})}
			theme={mandateTheme as EnumThemeLabel}
		/>
	) : undefined;

	const mandateTypeCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__mandate_type}`}
		>
			{mandateTypeElement}
		</div>
	);

	const areaElement = offer?.total_area_main_natures ? t('format.area', {
		val: offer.total_area_main_natures,
	}) : undefined;

	const areaCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__area}`}
		>
			{areaElement}
		</div>
	);

	const areaTableDataConf = [
	] as object[];
	lots?.collection?.forEach((lot: Lot) => {
		let lotAvailabilityDateState = null;

		switch (lot?.availability?.state) {
			case 'under_option':
			case 'under_promise':
				lotAvailabilityDateState = (
					<LabelStatus
						context='female'
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumStatusTheme.WARNING}
					/>
				);
				break;
			case 'suspended':
				lotAvailabilityDateState = (
					<LabelStatus
						context='female'
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumStatusTheme.ERROR}
					/>
				);
				break;
			case 'immediate_availability':
				lotAvailabilityDateState = (
					<LabelStatus
						context='female'
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumTheme.ELEVATION}
					/>
				);
		}
		const displayedNatures = lot?.natures?.length ? (
			lot.natures.map((nature: string) => {
				return t('format.capitalize', {
					text: t(`references.natures.${nature}`)
				});
			}).join(' - ')
		) : null;

		const lotData = {
			'building': lot?.building ? lot?.building : '-',
			'lot': lot?.reference ? lot?.reference : '-',
			'floor': lot?.floor ? lot?.floor : '-',
			'nature': displayedNatures ? displayedNatures : '-',
			'area': lot?.area?.value ? t('format.area', {
				val: lot?.area?.value
			}) : '-',
			'rental_price': lot?.rentalPrice?.price?.amount ? lot.rentalPrice.price.amount : '-',
			'sale_price': lot?.salePrice?.price?.amount ? lot?.salePrice.price.amount : '-',
			'availability': lotAvailabilityDateState || lot?.availability?.date_txt || lot?.availability?.date,
		};
		areaTableDataConf.push(lotData);
	});

	const areaBuilding = offer?.total_area_main_natures ?
		t('format.area', {
			'val': offer.total_area_main_natures,
		}) : undefined;

	let areaLot = undefined;
	if (offer?.lots_resume?.area_min && offer?.lots_resume?.area_max) {
		if (offer?.lots_resume?.area_min === offer?.lots_resume?.area_max) {
			areaLot = t('page.demand.offer.detail.spread_lot_unique', {
				'%count%': offer?.lots_resume?.count,
				'%max_area%': t('format.area', {
					'val': offer?.lots_resume?.area_max,
				}),
			});
		} else {
			areaLot = t('page.demand.offer.detail.spread_lot', {
				'%count%': offer?.lots_resume?.count,
				'%max_area%': t('format.area', {
					'val': offer?.lots_resume?.area_max,
				}),
				'%min_area%': t('format.area', {
					'val': offer?.lots_resume?.area_min,
				})
			});
		}
	}

	const lotsTableElement = (
		<Paragraph>
			<div className={styles.tooltip_lots}>
				<span className={styles.ref_title}>{t('format.capitalize', {
					text: t('page.demand.offer.lots_title', {
						'%ref%': offer.id.toString(),
					})
				})}</span>
				<span className={styles.area_title}>{areaBuilding}</span><span className={styles.lot_title}>{areaLot}</span>
			</div>
			<Table
				className={styles.table}
				columns={getColumns(offer)}
				data={areaTableDataConf}
				identifier={`table_offer_${offer?.id}`}
				options={{
					initialSort: [
						{
							'id': 'building',
							'desc': false
						}
					],
					sort: [
						'building',
						'lot',
						'floor'
					],
					pagination: {
						nbItemsPerPage: 5,
					}
				}}
				theme='column'
			/>
		</Paragraph>
	);

	const tooltipRef = useRef(null);

	const lotsElement = (
		<Button
			disabled={isLoadingLots}
			hasBoxShadow={false}
			iconLeft='area'
			iconStyle={EnumFontStyle.LIGHT}
			loader={isLoadingLots}
			name="area"
			theme={EnumTheme.NAKED}
			tooltipText={t('format.capitalize', {
				text: t('general.action.surface_area_table'),
			})}
			onClick={async () => {
				setIsLoadingLots(true);
				const getLotsOfferResponse = await getLotsOffer(offer.id);

				handleErrorResponse(getLotsOfferResponse, GetLotsOfferError);
				if (getLotsOfferResponse.status === 200) {
					setLots(new Collection(getLotsOfferResponse.payload, Lot, currentLanguage));
				}
				setIsLoadingLots(false);
			}}
		/>
	);
	const lotsCellElement = (
		<div
			className={`${styles.cell} ${styles.cell__lots}`}
		>
			<div ref={tooltipRef}>
				<TooltipModal
					className={styles.tooltip_modal}
					clickable={true}
					htmlElement={lotsTableElement}
					id={`lots-${offer.id}`}
					isOpen={isOpenTooltipModal}
					openOnClick={true}
					place={EnumTooltipPosition.LEFT_START}
					setIsOpen={setIsOpenTooltipModal}
					onCloseHover={true}
				>
					{lotsElement}
				</TooltipModal>
			</div>
		</div>
	);

	const actionsElement = (
		<div
			className={`${styles.cell} ${styles.cell__actions}`}
		>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Square}
				disabled={offer?.state === 'commercialized'}
				hasBoxShadow={false}
				iconLeft='edit'
				iconStyle={EnumFontStyle.LIGHT}
				isDisplay={true}
				label={isDesktopResolution ? undefined : t('format.capitalize', {
					text: t('general.action.edit')
				})}
				name='edit'
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={getURL(PATHS.OFFERS.EDIT.LOCATION, {
					':offerid': `${offer.id}`,
				})}
				tooltipText={isDesktopResolution ? t('format.capitalize', {
					text: t('general.action.edit')
				}) : undefined}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Square}
				hasBoxShadow={false}
				iconLeft='clone'
				iconStyle={EnumFontStyle.LIGHT}
				isDisplay={true}
				label={isDesktopResolution ? undefined : t('format.capitalize', {
					text: t('general.action.duplicate')
				})}
				name='clone'
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={getURL(PATHS.OFFERS.EDIT.LOCATION, {
					':offerid': `${offer.id}`,
				})}
				tooltipText={isDesktopResolution ? t('format.capitalize', {
					text: t('general.action.duplicate')
				}) : undefined}
			/>
		</div>
	);

	return (
		<div
			className={cssClasses.join(' ')}
		>
			{isDesktopResolution ? (
				<>
					{mainPhotoCellElement}
					{refCellElement}
					{natureCellElement}
					{statusCellElement}
					{statusDateCellElement}
					{privateCellElement}
					{mandateTypeCellElement}
					{areaCellElement}
					{lotsCellElement}
					{actionsElement}
				</>
			) : (
				<>
					<div className={styles.content}>
						<div
							className={styles.content__left}
						>
							{mainPhotoElement}
						</div>
						<div
							className={styles.content__right}
						>
							{natureElement}
							<div className={styles.container}>
								{refElement}
								{privateElement}
							</div>
							<div>{areaElement}</div>
							{statusElement}
							<div>{statusDateElement}</div>
						</div>
					</div>
					{actionsElement}
				</>
			)}
		</div>
	);
};

export default OfferRow;
