import {
	handleErrorResponse,
} from '@modules/utils';
import React, {
	useRef,
	useState,
} from 'react';
import {
	useTranslation, withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// FETCH
import DeleteOfferContactsError from '@exceptions/DeleteOfferContactsError';
import {
	deleteOfferContacts,
} from '../config/fetch';

// CONFIG
import columnTableConf from './config/columnTable.config';

// TYPES
import {
	ContactOffer,
} from '@@types/ContactOffer';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// LAYOUTS
import ModalOffersContact from '@layouts/Modals/ModalOffersContact';
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Button from '@components/button';
import Modal from '@components/modal';
import Table from '@components/table';
import ContactRow from './contact-row';

// STYLES
import Form from '@components/form';
import InputText from '@components/form/input-text';
import {
	EnumInputType,
} from '@enums/form.enum';
import styles from './contacts.module.scss';

const Contacts = () => {
	const { t } = useTranslation();

	const {
		isDesktopResolution,
		offerEnumsState,
		offerState,
		offerStateContacts,
		setOfferStateContacts,
		setOfferState,
	} : PageOfferContextProps = useOutletContext();

	const [
		error,
		setError
	] = useState(false);

	const [
		contactsIds,
		setContactsIds
	] = useState([
	] as number[]);

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const [
		selectedRowId,
		setSelectedRowId
	] = useState(undefined as number);

	const [
		reinitSelection,
		setReinitSelection
	] = useState(false);

	const dataTable = offerStateContacts?.contacts?.collection ? [
		...offerStateContacts.contacts.collection
	] : [
	];

	dataTable.push({
	} as ContactOffer);

	const modalElement = (
		<Modal
			hasHeader={true}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenModal}
			size={EnumModalSize.DEFAULT}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.modal.title')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<ModalOffersContact
				offerEnumsState={offerEnumsState}
				offerStateContacts={offerStateContacts}
				setIsOpenModal={setIsOpenModal}
				setOfferStateContacts={setOfferStateContacts}
			/>
		</Modal>
	);

	const buttonActionBar = (
		<Button
			corners={EnumButtonCorners.Square}
			hasBoxShadow={true}
			iconLeft={'trash-alt'}
			label={t('format.capitalize', {
				text: t('general.action.delete')
			})}
			name={'delete'}
			size={EnumButtonSize.SMALL}
			theme={isDesktopResolution ? EnumTheme.SECONDARY : EnumTheme.PRIMARY}
			onClick={async () => {
				const contactSelectedIds = contactsIds.filter(contactId => !!contactId);
				if (contactSelectedIds.length) {
					const deleteOfferContactsResponse = await deleteOfferContacts(offerState.id, contactSelectedIds);
					if (deleteOfferContactsResponse?.status) handleErrorResponse(deleteOfferContactsResponse, DeleteOfferContactsError);
					const offerContactsUpdated = {
						...offerStateContacts,
						contacts: {
							...offerStateContacts.contacts,
							collection: offerStateContacts.contacts.collection.filter(
								contact => !contactSelectedIds.includes(contact.id)
							)
						}
					};
					setOfferStateContacts({
						contacts: {
							...offerContactsUpdated.contacts,
							count: offerContactsUpdated.contacts.collection.length
						},
						is_loaded: offerContactsUpdated.is_loaded
					});
				}
				setReinitSelection(true);
			}}
		/>
	);

	const configurationActionBarTable = {
		type: t('page.offers_create_edit.contacts.modal.contacts'),
		offerId: offerState.id,
		actions: buttonActionBar,
	};

	const formContactsRef = useRef<HTMLFormElement>(null);

	const inputsContacts = (
		<Table
			actionBarClassName={styles.action_bar}
			className={styles.table}
			columns={columnTableConf}
			configurationActionBar={configurationActionBarTable}
			data={dataTable}
			identifier='table_contacts'
			options={{
				canSelect: true,
				hasHeader: isDesktopResolution,
				pagination: {
					nbItemsPerPage: 50,
				},
			}}
			reinitSelection={reinitSelection}
			renderSubComponent={(props) => {
				const handleRowClick = (rowId: number) => {
					setSelectedRowId(rowId);
				};
				const filteredContacts = dataTable?.filter((contact: ContactOffer) => contact.id === props.original.id);

				const currentContact = filteredContacts.length > 0 && filteredContacts[0].id ? new ContactOffer(filteredContacts[0]) : null;
				return (
					<ContactRow
						{...props}
						canSelect={true}
						currentOfferId={offerState.id}
						error={error}
						isDesktopResolution={isDesktopResolution}
						offerContact={currentContact}
						offerStateContacts={offerStateContacts}
						rowId={props.index}
						selectedRowId={selectedRowId}
						setError={setError}
						setIsOpenModal={setIsOpenModal}
						setOfferStateContacts={setOfferStateContacts}
						onClickRow={handleRowClick}
					/>
				);

			}}
			setComponentSelectedIds={setContactsIds}
			setReinitSelection={setReinitSelection}
			theme='column'
		/>
	);

	return (
		<div className={`${styles.section} ${styles.section__full}`}>
			<div className={styles.step_progress}>
				{t('format.capitalize', {
					text: `${t('page.offers_create_edit.step')} 3/8`
				})}
			</div>
			<div className={styles.legend}>{t('general.form.input.required')}</div>
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.title')
				})}
			</div>
			<div className={styles.title}>{t('page.offers_create_edit.contacts.mandate_contact_number')} ({offerStateContacts?.contacts?.collection?.length || '0'})</div>
			{inputsContacts}
			<Form
				innerRef={formContactsRef}
				onError={() => {
					setError(true);
				}}
			>
				<InputText
					name='contact_id'
					required={true}
					type={EnumInputType.HIDDEN}
					value={offerStateContacts?.contacts?.collection[0]?.contact_id.toString()}
				/>
			</Form>
			<ValidationForm
				formRef={formContactsRef}
				isDesktopResolution={isDesktopResolution}
				isValid={offerStateContacts?.contacts?.collection?.some(contact => !!contact.contact_id)}
				offerState={offerState}
				setOfferState={setOfferState}
			></ValidationForm>
			{modalElement}
		</div>
	);
};

export default withTranslation()(Contacts);
