import React, {
	useEffect,
	useState,
} from 'react';
import {
	useNavigate,
	useParams,
} from 'react-router-dom';
import {
	PowerBIEmbed,
	EmbedProps,
} from 'powerbi-client-react';
import {
	models,
} from 'powerbi-client';
import {
	ICustomEvent,
} from 'service';
import {
	useDispatch,
} from 'react-redux';
import {
	useTranslation,
} from 'react-i18next';

// SLICES
import {
	appSetRoute,
} from '@stores/_slices/app';
import {
	userLogout,
	userUpdateSession,
} from '@stores/_slices/user';
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	clearDemands,
} from '@stores/_slices/demands';

// ROUTES
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// EXCEPTIONS
import GetObservatoryReportError from '@exceptions/GetObservatoryReportError';
import GetUserAuthError from '@exceptions/GetUserAuthError';

// HOOKS
import useThrottledEffect from '@hooks/useTrottledEffect/hook.useTrottledEffect';
// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// CONGIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	getObservatoryReport,
	getUserAuth,
} from './config/fetch';

// COMPONENT
import Loader from '@components/loader';
import {
	ResponseParsedProps,
} from '@components/form';

// STYLING
import styles from './PageObservatoryReport.module.scss';

function PageObservatoryReport() {
	const { reportid } = useParams();
	const currentReportId = !isNaN(Number(reportid)) ? Number(reportid) : null;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [
		isLoading,
		setIsLoading
	] = useState(false);

	const [
		activeEvent,
		setActiveEvent
	] = useState(null);

	// eslint-disable-next-line
	const handleOnEvent = (event: ICustomEvent<any>) => {
		setActiveEvent(event.timeStamp);
	};

	const getUserAuthenticate = async (callback?: (payload: ResponseParsedProps) => void) => {
		const getUserAuthResponse = await getUserAuth();

		if (getUserAuthResponse.status === 200) {
			dispatch(userUpdateSession());

			if (callback) callback(getUserAuthResponse);
		} else {
			handleErrorResponse(getUserAuthResponse, GetUserAuthError);
			dispatch(userLogout());
			dispatch(clearDemands());
			// Manage error message
			dispatch(addToastMsg({
				message: t('format.capitalize', {
					text: t([
						`status.${getUserAuthResponse.statusText}`,
						'status.default'
					]),
				}),
				theme: EnumStatusTheme.ERROR,
			}));
		}
	};

	const defaultPowerBiConf: EmbedProps = {
		embedConfig: {
			type: 'report',   // Supported types: report, dashboard, tile, visual and qna
			id: null,
			embedUrl: null,
			accessToken: null,
			tokenType: models.TokenType.Embed,
		}
	};

	let counter = 0;
	const getReport = async () => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		setIsLoading(true);
		counter++;
		const getObservatoryReportResponse = await getObservatoryReport(currentReportId);
		handleErrorResponse(getObservatoryReportResponse, GetObservatoryReportError);

		switch (getObservatoryReportResponse.status) {
			case 200:
				setPowerBiConf({
					...defaultPowerBiConf,
					embedConfig: {
						...defaultPowerBiConf.embedConfig,
						id: getObservatoryReportResponse.payload.power_bi_report_id,
						embedUrl: getObservatoryReportResponse.payload.power_bi_embed_url,
						accessToken: getObservatoryReportResponse.payload.access_token,
						settings: getObservatoryReportResponse.payload.settings
					}
				});
				setIsLoading(false);
				break;
			default:
				if (counter < 5) {
					getReport();
				} else if (getObservatoryReportResponse.error === 'access_denied') {
					// STORING THE PREVIOUS ROUTE BEFORE AUTH
					dispatch(appSetRoute({
						name: 'from',
						url: location.pathname
					}));

					dispatch(addStatusMsg({
						message: t('status.error_session'),
						theme: EnumStatusTheme.ERROR,
					}));
				} else {
					navigate(PATHS.ERROR._ROOT.replace(':code', '404'));
					throw new GetObservatoryReportError(getObservatoryReportResponse.message);
				}
		}
	};

	const [
		powerBiConf,
		setPowerBiConf
	] = useState(null);

	useEffect(() => {
		getReport();
	}, [
		reportid,
	]);

	useThrottledEffect(() => {
		const refreshTotken = async () => {
			await getUserAuthenticate();
			const getObservatoryReportResponse = await getObservatoryReport(currentReportId);
			setPowerBiConf({
				...defaultPowerBiConf,
				embedConfig: {
					...defaultPowerBiConf.embedConfig,
					accessToken: getObservatoryReportResponse?.payload?.access_token,
				}
			});
		};

		refreshTotken();
	}, [
		activeEvent
	], APP_CONF_VARS.timeout.reload_session);

	const powerBiElement = powerBiConf ? (
		<div className={styles.wrapper}>
			<PowerBIEmbed
				cssClassName={'report-style-class'}
				embedConfig={powerBiConf.embedConfig}
				eventHandlers={
					new Map([
						[
							'loaded',
							(event) => handleOnEvent(event)
						],
						[
							'rendered',
							(event) => handleOnEvent(event)
						],
						[
							'error',
							function (event) { throw new GetObservatoryReportError(event.detail); }
						],
						[
							'visualClicked',
							(event) => handleOnEvent(event)
						],
						[
							'pageChanged',
							(event) => handleOnEvent(event)
						],
					])
				}
			/>
		</div>
	) : 'No report found';

	return (
		<div className={styles.PageObservatoryReport}>
			{isLoading ? <Loader /> : powerBiElement}
		</div>
	);
}

export default PageObservatoryReport;
