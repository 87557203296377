import React, {
	ReactElement,
	ReactNode,
	useState,
	useEffect,
	isValidElement,
	cloneElement,
	Children,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

import {
	EnumToggleButton,
} from '@enums/button.enum';

import InputCheckbox from '@components/form/input-checkbox';
import ToggleButtonList from '@components/toggle-button-list';
import ToggleButton, {
	ToggleButtonProps,
} from '@components/toggle-button-list/toggle-button';

import styles from './input-toggle-button.module.scss';
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	InputProps,
} from '../input';

interface MethodsProps {
	register?: (name: string, rules: object) => InputToggleButtonProps;
	formState?: {
		errors?: {
			[key: string]: string;
			name?: string;
		};
	};
	setError?: (name?: string) => void;
	setValue?: (name?: string, value?: unknown) => void;
	clearErrors?: (name?: string) => void;
}

interface InputToggleButtonProps extends Omit<InputProps, 'hasBorder' | 'hasShadow' | 'label' | 'onChange'> {
  'data-testid'?: string;
  activeButtonValues?: string[];
  children: ReactNode;
  className?: string;
  isLockedSelection?: boolean;
  isMultiselect?: boolean;
  methods?: MethodsProps;
  name?: string;
  onChange?: (selectedButtonIds: string[]) => void;
  required?: boolean;
  theme?: EnumToggleButton;
  validationType?: string;
}

const InputToggleButton = ({
	'data-testid': dataTestid,
	activeButtonValues = [
	],
	children,
	className,
	customError,
	invalid,
	isLockedSelection = false,
	isMultiselect,
	methods,
	name,
	onChange,
	required,
	theme,
	validationType,
}: InputToggleButtonProps): JSX.Element => {
	const { t } = useTranslation();
	const isControlled = !!methods?.register;

	const [
		selectedButtonIds,
		setSelectedButtonIds
	] = useState<string[]>(activeButtonValues);

	const registerRules: InputProps['registerRules'] = {
	};

	/* istanbul ignore next */
	if (methods && activeButtonValues.length) {
		methods.setValue(name, activeButtonValues ? activeButtonValues : undefined);
	}

	/* istanbul ignore next */
	if (isControlled && validationType && required) {
		const errorMessage = isMultiselect
			? t('general.form.input.error.toggle_multiselect')
			: t('general.form.input.error.toggle');

		registerRules.required = {
			value: true,
			message: errorMessage,
		};
	}

	/* istanbul ignore next */
	useEffect(() => {
		if (isControlled && name && methods) {
			methods.register(name, registerRules);
		}
	}, [
		isControlled,
		name,
		methods,
		registerRules
	]);

	const handleToggleChange = (id: string) => {
		let updatedActiveIds: string[];

		if (isMultiselect) {
			updatedActiveIds = selectedButtonIds.includes(id)
				? selectedButtonIds.filter(activeId => activeId !== id)
				: [
					...selectedButtonIds,
					id
				];
		} else {
			if (isLockedSelection) {
				updatedActiveIds = [
					id
				];
			} else {
				updatedActiveIds = selectedButtonIds.includes(id) ? [
				] : [
					id
				];
			}
		}

		setSelectedButtonIds(updatedActiveIds);
		if (onChange) onChange(updatedActiveIds);

		/* istanbul ignore next */
		if (methods) {
			methods.setValue(name, updatedActiveIds);
		}

	};

	const clonedToggleButtons = Children.map(children, (child) => {
		if (isValidElement(child) && child.type === ToggleButton) {
			return cloneElement(child as ReactElement<ToggleButtonProps>, {
				isActive: selectedButtonIds.includes(child.props.id),
				'data-testid': `${dataTestid}-button-${child.props.id}`,
				theme,
			});
		}
		return null;
	});

	const clonedCheckboxes = Children.map(children, (child) => {
		if (isValidElement(child) && child.type === ToggleButton) {
			const id = child.props.id;
			return (
				<InputCheckbox
					checked={selectedButtonIds.includes(id)}
					className={styles.checkbox}
					data-testid={`${dataTestid}-checkbox-${id}`}
					id={id}
					name={name}
				/>
			);
		}
		return null;
	});

	const errorMsgText = invalid?.message || customError;
	const errorMsgElement = errorMsgText && !selectedButtonIds.length && (methods?.formState?.errors[name]) ? (
		<div
			className={styles.error_message}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{errorMsgText}
		</div>
	) : null;

	const classes = [
		styles.input_toggle_button
	];
	if (className) classes.push(className);
	if ((methods?.formState?.errors[name]) && required && !selectedButtonIds.length) classes.push(styles.invalid);

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			<ToggleButtonList
				activeButtonValues={selectedButtonIds}
				id={'toggle-button-list'}
				theme={theme}
				onClick={handleToggleChange}
			>
				{clonedToggleButtons as ReactElement<ToggleButtonProps> | ReactElement<ToggleButtonProps>[]}
			</ToggleButtonList>

			{clonedCheckboxes}
			{errorMsgElement}
		</div>
	);
};

InputToggleButton.displayName = EnumComponentType.INPUT_TOOGLE_BUTTON;

export default InputToggleButton;
