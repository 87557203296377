// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upfifFnv{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.upfifFnv{padding:1.42857143rem}}.fGp1f8Xa{display:flex;flex-direction:column;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.fGp1f8Xa{flex-shrink:0;width:40.71428571rem}}.fGp1f8Xa .hAd8jThi{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.fGp1f8Xa .ylbY2tDo{margin-bottom:1.42857143rem;font-size:0.85714286rem}.fGp1f8Xa .TOTIeVYf{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.fGp1f8Xa .TOTIeVYf:first-letter{display:block;text-transform:uppercase}.Vz6Xtr7Q{display:flex;justify-content:center;width:100%}.Vz6Xtr7Q .yS4Bgsua{padding-top:2.14285714rem;width:auto}.dpRkULDb{display:flex;flex-direction:row;gap:1.42857143rem}.dpRkULDb>*{flex:1}.eM5hYgBs{display:flex;flex-direction:column}.qbYzio64{width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.qbYzio64{width:50%}}.a4BmZayq{margin-bottom:2.14285714rem}.a4BmZayq>div{margin:0 0 1.07142857rem}.a4BmZayq>div:last-child{margin:0}.xTwPZXUT{width:100%}.KQTvUGCd{margin-left:0.71428571rem;width:max-content}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `upfifFnv`,
	"section__left": `fGp1f8Xa`,
	"step_progress": `hAd8jThi`,
	"legend": `ylbY2tDo`,
	"title": `TOTIeVYf`,
	"section__right": `Vz6Xtr7Q`,
	"image": `yS4Bgsua`,
	"form_row": `dpRkULDb`,
	"form_column": `eM5hYgBs`,
	"input": `qbYzio64`,
	"checkbox": `a4BmZayq`,
	"full_select": `xTwPZXUT`,
	"tooltip": `KQTvUGCd`
};
export default ___CSS_LOADER_EXPORT___;
