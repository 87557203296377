import React, {
	MouseEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useLocation,
	useOutletContext,
} from 'react-router-dom';

// EXCEPTIONS
import GetSearchAddressByIdError from '@exceptions/GetSearchAddressByIdError';
import GetSearchAddressesByStreetCityError from '@exceptions/GetSearchAddressesByStreetCityError';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// EXCEPTIONS
import PostOfferSearchError from '@exceptions/PostOfferSearchError';

// HOOKS
import useClickOutsideEffect from '@hooks/useClickOutsideEffect/hook.useClickOutsideEffect';

// MODULES
import dataUtils from '@modules/data-utils';
import utilsText from '@modules/text';
import {
	handleErrorResponse,
} from '@modules/utils';

// LAYOUTS
import Table from '@components/table';
import {
	AddressesProps,
	OfferStateProps,
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	getSearchAddressById,
	getSearchAddressesByStreetCity,
	postOfferSearch,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	columnLocationTable,
} from '@layouts/Pages/PageOfferEntry/location/config/column-location-table.config';
import OfferRow from '@layouts/Pages/PageOfferEntry/location/offer-row';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputRadioGroup from '@components/form/input-radio-group';
import InputRadio from '@components/form/input-radio-group/input-radio';
import InputText from '@components/form/input-text';
import Icon from '@components/icon';
import Image from '@components/image';
import Modal from '@components/modal';
import StatusBanner from '@components/status-banner';
import SuggestionList from '@components/suggestion-list';
import Suggestion from '@components/suggestion-list/suggestion';

// ASSETS
import locationImage from '@assets/images/lpdipro/illu_men_location.png';

// STYLES
import styles from './location.module.scss';

const Location = () => {
	const {t} = useTranslation();
	const {
		offerState,
		setOfferState,
		isDesktopResolution,
	}: PageOfferContextProps = useOutletContext();

	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const addressId = urlParams.get('address_id');

	const [
		addresses,
		setAddresses
	] = useState(undefined);

	const onSubmitSuccess = async (data: Record<string, undefined>) => {
		setIsLoadingSuggestions(true);

		try {
			const responseParsed = await getSearchAddressesByStreetCity(data.street_raw, data.city_raw);

			// Check if the request is successful and data exists
			if (responseParsed.status === 200 && responseParsed.payload?.length) {
				// Format the retrieved addresses
				const formattedAddresses = responseParsed.payload.map((addressRequest: Record<string, unknown>) => ({
					icon: addressRequest?.id ? 'building' : 'map-marker-alt',
					type: addressRequest?.id ? 'building' : 'address',
					id: addressRequest?.id,
					decoded: addressRequest?.decoded,
					is_main: !offerState?.addresses?.length, // Set is_main to true if no address exists
					is_valid: addressRequest?.is_valid,
					street_raw: addressRequest?.street_raw,
				}));

				// Filter out addresses already present in offerState
				const filteredAddresses = formattedAddresses.filter(
					(formattedAddress: Record<string, string>) =>
						!offerState?.addresses?.some(
							offerAddress => utilsText.normalizeText(offerAddress.street_raw) === utilsText.normalizeText(formattedAddress.street_raw)
						)
				);

				setAddresses(filteredAddresses);
			} else {
				// Reset addresses if payload is empty
				setAddresses([
				]);
				handleErrorResponse(responseParsed, GetSearchAddressesByStreetCityError);
			}
		} catch (error) {
			// Handle request errors
			setAddresses([
			]);
		} finally {
			// Disable loading state at the end of the request
			setIsLoadingSuggestions(false);
		}
	};

	const refAddressesForm = useRef();

	const handleOnClickOutside = () => {
		setAddresses(null);
	};

	useClickOutsideEffect([
		refAddressesForm
	], handleOnClickOutside);

	const [
		isOpen,
		setIsOpen
	] = useState(false);

	const [
		customAddressState,
		setCustomAddressState
	] = useState({
		street_raw: undefined,
		pretty_street_raw: undefined,
		isOpen: false
	});

	const [
		isLoadingSuggestions,
		setIsLoadingSuggestions
	] = useState(false);

	const [
		suggestionOffers,
		setSuggestionOffers
	] = useState({
		is_loaded: false,
		offers: undefined
	});

	const formCustomAddressRef = useRef(null);

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setCustomAddressState({
			...customAddressState,
			isOpen: false
		});
	});

	useEffect(() => {
		const postSearchAddressData = async () => {
			try {
				const getSearchAddressByIdResponse = await getSearchAddressById(Number(addressId));
				handleErrorResponse(getSearchAddressByIdResponse, GetSearchAddressByIdError);

				if (getSearchAddressByIdResponse.status === 200) {
					setOfferState({
						addresses: [
								{
									id: getSearchAddressByIdResponse.payload.id,
									is_main: true,
									street_raw: getSearchAddressByIdResponse.payload.street_raw,
								} as AddressesProps,
						],
					});
				}

				const searchReponse = await postOfferSearch(getSearchAddressByIdResponse.payload.id);
				handleErrorResponse(searchReponse, PostOfferSearchError);
				if (searchReponse.status === 200) {
					setSuggestionOffers({
						is_loaded: true,
						offers: searchReponse.payload,
					});
				}
			} catch (error) {
				console.error(error);
			}
		};
		if (addressId) {
			postSearchAddressData();
		} else {
			setSuggestionOffers({
				is_loaded: true,
				offers: undefined,
			});
		}
	}, [
		location
	]);

	const modalCustomAddress = customAddressState.isOpen ? (
		<Modal
			hasHeader={true}
			isDesktopResolution={isDesktopResolution}
			isOpen={customAddressState.isOpen}
			size={EnumModalSize.DEFAULT}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.location.custom_address_offer')
			})}
			onClose={() => {
				setCustomAddressState({
					...customAddressState,
					isOpen: false
				});
			}}
		>
			<Form
				className={styles.form__modal_address}
				innerRef={formCustomAddressRef}
				method={EnumQueryMethods.GET}
				onSuccess={(data: Record<string, string>) => {
					const addressesArray = [
					] as AddressesProps[];

					if (offerState?.addresses?.length) {
						offerState?.addresses.forEach(address => {
							if (address.street_raw === customAddressState.street_raw) {
								addressesArray.push({
									...address,
									pretty_street_raw: data.pretty_street_raw
								});
								setCustomAddressState({
									...customAddressState,
									isOpen: false
								});
							} else {
								addressesArray.push(address);
							}
						});
						const updatedSteps = offerState?.updated_steps?.length ? {
							...offerState.updated_steps,
							location: true,
						} : {
							location: true,
						};
						setOfferState({
							...offerState,
							addresses: [
								...addressesArray,
							],
							steps: {
								...offerState?.steps,
								location: false,
							},
							updated_steps: updatedSteps,
						});
					}
				}}
			>
				<InputText
					autoComplete='pretty_street_raw'
					className={styles.input}
					defaultValue={customAddressState.pretty_street_raw}
					label={t('format.capitalize', {
						text: t('general.form.input.type.street.label')
					})}
					name='pretty_street_raw'
					placeholder={t('format.capitalize', {
						text: t('general.form.input.type.street.placeholder')
					})}
					required={true}
					type={EnumInputType.TEXT}
				/>

				<div className={styles.footer_buttons}>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.confirm')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</Form>
		</Modal>
	) : undefined;

	const noResultsElement = (
		<li
			className={styles.noresults_element}
		>
			<Icon
				className={styles}
				fontStyle={EnumFontStyle.LIGHT}
				name='search-slash'
			/>
			<span>{t('page.offers_create_edit.location.no_results_address')}</span>
		</li>
	);

	const suggestionElement = (
		<SuggestionList
			className={styles.addresses_suggestions}
			hasBorder={isDesktopResolution ? true : false}
			hasShadow={isDesktopResolution ? true : false}
			isLoading={isLoadingSuggestions}
			suggestionsHTML={addresses?.length === 0 ? noResultsElement : undefined}
		>
			{addresses?.map((address: AddressesProps, key: number) => {
				return address ? (
					<div key={key}>
						<Suggestion
							icon={address.icon}
							text={address.street_raw}
							type={address.type}
							onClick={async (event) => {
								event.preventDefault();

								const addressesArray = [
								] as AddressesProps[];
								if (offerState?.addresses?.length) {
									offerState?.addresses.forEach(address => {
										addressesArray.push(address);
									});
								}

								const updatedSteps = offerState?.updated_steps?.length ? {
									...offerState?.updated_steps,
									location: true,
								} : {
									location: true,
								};
								setOfferState({
									...offerState,
									addresses: [
										...addressesArray,
										{
											decoded: address.decoded,
											id: address.id,
											is_main: address.is_main,
											street_raw: address.street_raw,
										} as AddressesProps,
									],
									steps: {
										...offerState?.steps,
										location: false,
									},
									updated_steps: updatedSteps,
								});
								setAddresses(undefined);
								if (!isDesktopResolution) {
									setIsOpen(false);
								}

								if (address.id && !offerState?.addresses?.length) {
									const searchReponse = await postOfferSearch(address.id);
									handleErrorResponse(searchReponse, PostOfferSearchError);
									if (searchReponse.status === 200) {
										setSuggestionOffers({
											is_loaded: true,
											offers: searchReponse.payload,
										});
									}
								}
							}}
						/>
						{address.is_valid ? null : (
							<StatusBanner
								className={styles.invalid_postal_address}
								message={t('format.capitalize', {
									text: t('page.offers_create_edit.location.invalid_postal_address')
								})}
								theme={EnumStatusTheme.WARNING}
							/>
						)}
					</div>
				) : undefined;
			})}
		</SuggestionList>
	);

	const optionList = offerState?.addresses?.length ? offerState.addresses.map((address, index) => {
		const choiceId = `address_${index}`;

		const customAddressTranslation = address?.pretty_street_raw ? t('general.customized', {
			context: 'female',
		}) : '';
		const labelRadio = (
			<div
				className={styles.addresses_choice}
			>
				<span>{address?.pretty_street_raw ? `${address.pretty_street_raw} (${customAddressTranslation})` : address.street_raw}</span>
				<Button
					corners={EnumButtonCorners.Square}
					hasBoxShadow={false}
					iconLeft='edit'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
					type={EnumButtonType.BUTTON}
					onClick={() => {
						setCustomAddressState({
							street_raw: address.street_raw,
							pretty_street_raw: address?.pretty_street_raw || address.street_raw,
							isOpen: true
						});
					}}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					hasBoxShadow={false}
					iconLeft='trash-alt'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
					type={EnumButtonType.BUTTON}
					onClick={() => {
						let formatedAddresses = offerState?.addresses.filter((_, addrIndex) => addrIndex !== index);

						const hasMainAddress = formatedAddresses.some((address) => address.is_main);

						if (!hasMainAddress && formatedAddresses.length > 0) {
							formatedAddresses = formatedAddresses.map((address, addrIndex) => {
								return ({
									...address,
									is_main: addrIndex === 0 ? true : address.is_main,
								});
							});
						}

						const updatedSteps = offerState?.updated_steps?.length ? {
							...offerState?.updated_steps,
							location: true,
						} : {
							location: true,
						};

						setOfferState({
							...offerState,
							addresses: formatedAddresses,
							steps: {
								...offerState?.steps,
								location: false,
							},
							updated_steps: updatedSteps,
						});
					}}
				/>
			</div>
		);

		return {
			className: styles.input_choice,
			label: labelRadio,
			name: 'main_address',
			key: index,
			id: choiceId,
			value: JSON.stringify(address),
			checked: address.is_main,
		};

	}) : undefined;

	const indexOfMainAddress = offerState?.addresses?.findIndex(address => address.is_main === true);

	const inputRadioGroupElement = offerState?.addresses?.length ? (
		<div
			className={styles.input_radio_group}
		>
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.location.main_address_offer')
				})}
			</div>
			<InputRadioGroup
				name="choice_address"
				selectedIndex={indexOfMainAddress !== -1 ? indexOfMainAddress : 0}
				onChange={async (event) => {
					const mainAddress = JSON.parse(event.target.value);

					const formatedAddresses = offerState?.addresses.map((address: AddressesProps) => {
						return {
							decoded: address.decoded,
							id: address.id,
							is_main: mainAddress.street_raw === address.street_raw,
							street_raw: address.street_raw,
							pretty_street_raw: address?.pretty_street_raw,
						};
					});
					const updatedSteps = offerState?.updated_steps?.length ? {
						...offerState?.updated_steps,
						location: true,
					} : {
						location: true,
					};
					setOfferState({
						...offerState,
						addresses: [
							...formatedAddresses,
						],
						steps: {
							...offerState?.steps,
							location: false,
						},
						updated_steps: updatedSteps,
					});

					if (mainAddress.id) {
						const searchReponse = await postOfferSearch(mainAddress.id);
						handleErrorResponse(searchReponse, PostOfferSearchError);
						if (searchReponse.status === 200) {
							setSuggestionOffers({
								is_loaded: true,
								offers: searchReponse.payload,
							});
						}
					}
				}}
			>
				{optionList?.map((option, key) => {
					return (
						<InputRadio
							{...option}
							key={key}
						/>
					);
				})}
			</InputRadioGroup>
		</div>
	) : undefined;

	const handleOnChangePagination = (event: MouseEvent<HTMLElement>) => {
		const target = event.target as HTMLElement;
		const content = target.closest(`.${styles.section__table}`);
		content.scrollTop = 0;
	};

	const formeatedOffers = suggestionOffers?.offers?.collection?.map((offer: OfferStateProps) => dataUtils.flattenObject(offer));

	const sectionTable = (
		<>
			{suggestionOffers.offers?.collection?.length ? (
				<>
					<div className={styles.title}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.location.check_offers')
						})}
					</div>
					<p>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.location.check_offers_description')
						})}
					</p>
				</>
			) : undefined}
			<div className={styles.section__table}>
				{suggestionOffers.offers?.collection?.length ? (
					<Table
						// columnFilters={columnFilters}
						columns={columnLocationTable}
						// customNoResult={noResultElement}
						data={formeatedOffers}
						identifier='table_suggestion_offers'
						options={{
							pagination: {
								nbItemsPerPage: 50,
								onChange: (event) => {
									handleOnChangePagination(event);
								}
							},
						}}
						renderSubComponent={(props) => {
							const filteredOffers = suggestionOffers.offers?.collection?.filter((offer: OfferStateProps) => offer.id === props.original.id);
							const currentOffer = filteredOffers.length > 0 && filteredOffers[0].id ? filteredOffers[0] : undefined;

							return (
								<OfferRow
									{...props}
									isDesktopResolution={isDesktopResolution}
									offer={currentOffer}
								/>
							);
						}}
						theme='column'
					/>
				) : undefined}
			</div>
		</>
	);

	const formRef = useRef(null);

	const modalAddressMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpen}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.location.offer_address')
			})}
			onClose={() => {
				setIsOpen(false);
			}}
		>
			<Form
				className={styles.form__modal}
				innerRef={formRef}
				method={EnumQueryMethods.GET}
				onSuccess={onSubmitSuccess}
			>
				<InputText
					autoComplete='street-address'
					className={styles.input}
					customError={t('format.capitalize', {
						text: t('general.form.input.error.address')
					})}
					label={t('format.capitalize', {
						text: t('general.form.input.type.street.label')
					})}
					name='street_raw'
					placeholder={t('format.capitalize', {
						text: t('general.form.input.type.street.placeholder')
					})}
					required={true}
					type={EnumInputType.TEXT}
				/>
				<div
					className={styles.input_group}
					ref={refAddressesForm}
				>
					<InputText
						autoComplete='street-address'
						className={styles.input}
						customError={t('format.capitalize', {
							text: t('general.form.input.error.city')
						})}
						label={t('format.capitalize', {
							text: t('general.form.input.type.city.label')
						})}
						name='city_raw'
						placeholder={t('format.capitalize', {
							text: t('general.form.input.type.city.placeholder')
						})}
						required={true}
						type={EnumInputType.TEXT}
					/>
					<Button
						className={styles.button}
						corners={EnumButtonCorners.Square}
						iconLeft='search'
						type={EnumButtonType.SUBMIT}
					/>
					{suggestionElement}
				</div>
			</Form>
		</Modal>
	);

	return (
		<div className={styles.section}>
			<div className={styles.section__left}>
				<div className={styles.step_progress}>
					{t('format.capitalize', {
						text: `${t('page.offers_create_edit.step')} 1/8`
					})}
				</div>
				<div className={styles.legend}>{t('general.form.input.required')}</div>
				<div className={styles.title}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.location.where_is_your_offer')
					})} {<span>*</span>}
				</div>
				{isDesktopResolution ? (
					<div
						className={styles.container}
						ref={refAddressesForm}
					>
						<Form
							className={styles.form}
							innerRef={formRef}
							method={EnumQueryMethods.GET}
							onSuccess={onSubmitSuccess}
						>
							<InputText
								autoComplete='street-address'
								className={styles.input}
								customError={t('format.capitalize', {
									text: t('general.form.input.error.address')
								})}
								label={t('format.capitalize', {
									text: t('general.form.input.type.street.label')
								})}
								name='street_raw'
								placeholder={t('format.capitalize', {
									text: t('general.form.input.type.street.placeholder')
								})}
								required={true}
								type={EnumInputType.TEXT}
							/>
							<InputText
								autoComplete='street-address'
								className={styles.input}
								customError={t('format.capitalize', {
									text: t('general.form.input.error.city')
								})}
								label={t('format.capitalize', {
									text: t('general.form.input.type.city.label')
								})}
								name='city_raw'
								placeholder={t('format.capitalize', {
									text: t('general.form.input.type.city.placeholder')
								})}
								required={true}
								type={EnumInputType.TEXT}
							/>
							<Button
								className={styles.button}
								corners={EnumButtonCorners.Square}
								iconLeft='search'
								type={EnumButtonType.SUBMIT}
							/>
						</Form>
						{suggestionElement}
					</div>
				) : (
					<Button
						className={styles.trigger_modal}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={false}
						iconLeft='map-marker-alt'
						iconStyle={EnumFontStyle.LIGHT}
						label={t('format.capitalize', {
							text: t('general.form.input.type.address.placeholder')
						})}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.BUTTON}
						onClick={() => {
							setIsOpen(true);
						}}
					/>
				)}
				{inputRadioGroupElement ? (
					<Form>
						{inputRadioGroupElement}
					</Form>
				) : undefined}
				{offerState?.addresses?.length ? undefined : (
					<ValidationForm
						formRef={formRef}
						isDesktopResolution={isDesktopResolution}
						isValid={offerState?.addresses?.length ? true : false}
						offerState={offerState}
						setIsOpen={isDesktopResolution ? undefined : setIsOpen}
						setOfferState={setOfferState}
					/>
				)}
			</div>
			{isDesktopResolution && (
				<div className={styles.section__right}>
					<Image
						alt='placeholder'
						className={styles.image}
						src={locationImage}
					/>
				</div>
			)}
			{offerState?.addresses?.length ? (
				<div className={styles.section__offers}>
					<ValidationForm
						formRef={formRef}
						isDesktopResolution={isDesktopResolution}
						isValid={offerState?.addresses?.length ? true : false}
						offerState={offerState}
						setIsOpen={isDesktopResolution ? undefined : setIsOpen}
						setOfferState={setOfferState}
					>
						{sectionTable}
					</ValidationForm>
				</div>
			) : undefined}
			{modalCustomAddress}
			{isDesktopResolution ? undefined : modalAddressMobile}
		</div>
	);
};

export default withTranslation()(Location);
